import { pingUserDataTypes } from '../ApacUserService/ApacUserService'
import axiosInstance from '../../helpers/AxiosHelper'

export interface getTokenByCodeTypes {
  success:string
  expires_in: string
}

export function getAuthCode() {
  const { REACT_APP_PING_URL, REACT_APP_COS_PING_CLIENT_ID, REACT_APP_PING_REDIRECT_URL } = process.env
  const authUrl = `${REACT_APP_PING_URL}/as/authorization.oauth2?client_id=${REACT_APP_COS_PING_CLIENT_ID}&redirect_uri=${REACT_APP_PING_REDIRECT_URL}&scope=openid profile groups phone&prompt=login&response_type=code`
  window.location.href = authUrl
}

export async function getPingTokenByCode(code: string): Promise<getTokenByCodeTypes> {
  const data = {
    code: code,
    tokenType: 'Bearer'
  }
  try {
    const response = await axiosInstance.post('/getTokenWithCode', data)
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getPingUserData(): Promise<pingUserDataTypes> {
  try {
    const response = await axiosInstance.get('/user-info')
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getAccessTokenWithRefreshToken():Promise<getTokenByCodeTypes> {
  try {
    const response = await axiosInstance.get('/refresh')
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function signOutCOS() {
  try {
    const response = await axiosInstance.post('/logout')
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return Promise.reject(e)
  }
}
