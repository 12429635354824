import axiosInstance from '../helpers/AxiosHelper'

export class DigitalReturnsService {
  static async getReturns(currentStore: string): Promise<any> {
    try {
      const response = await axiosInstance.get<any>(`/returns/storeNumber/${currentStore}`)
      return response.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
