import axiosInstance from '../helpers/AxiosHelper'

export class PowerbiReportsService {
  static async getPowerBiReports(userName: string | undefined): Promise<any> {
    const config = {
      params: {
        userName: userName
      }
    }
    try {
      const response = await axiosInstance.get<any>('/reports/getReportInfo', config)
      return response.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
