import { Box, Button, Typography } from "@mui/material"
import { AddressInfo, formatLatLang, formatPhoneNumber } from "../../../../utilities/services/CustomerService/CustomerService"
import { customerDetailsStyles } from "../CustomerDetailsStyles"
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import { ReactComponent as DropOffPin } from '../../../../assets/drop_off_pin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { LanguageContext } from "../../../../utilities/contexts/LanguageContext"
import { useContext } from "react"
import language from "../../../../language/language"
import React from "react"

interface InputProps {
    addressInfo: AddressInfo,
    onEdit: () => void,
    index: number,
    redPin: [number, number] | null,
    pinLabel: string | null,
    addressEdit: number
}

const CustomerAddressView = ({addressInfo, onEdit, index, redPin, pinLabel, addressEdit}: InputProps) => {
    const { currentLanguage } = useContext(LanguageContext)

    return <Box sx={{ textAlign: 'left' }} display="flex" flexDirection={"column"} gap={"16px"}>
    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
        <Typography sx={customerDetailsStyles.typoHeader5}>
            {(language as any)[currentLanguage].location} {index + 1}: {addressInfo?.addressLabel} {!!pinLabel && `- ${pinLabel}`}
        </Typography>
        {(addressEdit<0) && <Button variant="text" sx={customerDetailsStyles.paragraph2Link} onClick={onEdit}>Edit</Button>}
    </Box>
    <Box>
        <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
            <PinIcon />
            <Typography sx={customerDetailsStyles.addressLabel}>{(language as any)[currentLanguage].address}</Typography>
            {addressInfo?.isPreferredAddress === 'Y' && <Typography sx={customerDetailsStyles.defaultAddress}>
                {(language as any)[currentLanguage].defaultLocation}
            </Typography>}
        </Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{addressInfo?.addressline1} {(!!addressInfo?.addressline2 && addressInfo.addressline2 !== "") ? `, ${addressInfo?.addressline2}` : ''}</Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{addressInfo?.city}, {addressInfo?.state} {addressInfo?.zip}</Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{!!addressInfo?.phoneNumber ? formatPhoneNumber(addressInfo?.phoneNumber) : ""}</Typography>
    </Box>
    <Box>
        <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
            <DropOffPin />
            {(language as any)[currentLanguage].dropOffSpot}
        </Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>
            {!!pinLabel && !!redPin
                ? pinLabel
                : "None"}
        </Typography>
        {!!redPin && <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>{formatLatLang(redPin[1], redPin[0])}</Typography>}
    </Box>
    <Box>
        <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
            <NotesIcon />
            {(language as any)[currentLanguage].deliveryNotes}
        </Typography>
        <Typography sx={{ ...customerDetailsStyles.paragraph2, paddingLeft: '21px' }}>
            {addressInfo?.note ? addressInfo?.note : 'None'}
        </Typography>
    </Box>
</Box>
}

export default CustomerAddressView