import axiosInstance from '../helpers/AxiosHelper'

export type TermsHistory = {
  username: string
  creationTimestamp?: number
  accepted: boolean
  timestamp: number
  version: number
}

export type Terms = {
  version: number
  body: string
  creationTimestamp: number
}

export class TermsAndConditionsService {
  static TERMS_ENDPOINT_V2 = '/terms/v2'

  static async getUserAcceptedLatestTerms(username: string): Promise<boolean> {
    try {
      const response = await axiosInstance.get<boolean>(
        `${this.TERMS_ENDPOINT_V2}/username/${username}`
      )
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getLatestTerms(): Promise<Terms> {
    try {
      const response = await axiosInstance.get<Terms>(this.TERMS_ENDPOINT_V2)
      if (response.status === 200) {
        return Promise.resolve(response.data)
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async saveUserTermsHistory(username: string, termsVersion: number): Promise<void> {
    const termsHistory: TermsHistory = {
      username: username,
      accepted: true,
      timestamp: new Date().getUTCMilliseconds(),
      version: termsVersion
    }
    try {
      const response = await axiosInstance.post<void>(
        `${this.TERMS_ENDPOINT_V2}/termsHistory`,
        termsHistory
      )
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
