import axiosInstance from '../helpers/AxiosHelper'

export class FeatureConfigurationService {
  static async getFeatureConfiguration(): Promise<any> {
    try {
      const response = await axiosInstance.get<any>('/configuration')
      return response.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
