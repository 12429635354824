import { Box, Button, CircularProgress, IconButton, Radio, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import language from '../../../../language/language'
import { TransferCustomerSearchStyles } from './TransferCustomerSearchStylesType'
import { KeyboardArrowDownSharp, KeyboardArrowUpSharp } from '@mui/icons-material'
import { invoiceListStyles } from '../InvoiceList/invoiceListStyles'
import CloseIcon from '@mui/icons-material/Close'
import { useCustomerDetailsByStoreMultiple } from '../../../../queries/Customer/useCustomerDetailsByStore'
import { AddressInfo, DeliveryGeoCodes } from '../../../../utilities/services/CustomerService/CustomerService'
import { ReactComponent as PinIcon } from '../../../../assets/dropPin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { TransferStop } from '../../../../utilities/types/DeliveryRouteTypes'

type CustomerSearchProps = {
  setDestinationStop: (value: TransferStop) => void
  closeSearchCallback: () => void
}

const TransferCustomerSearch = ({ setDestinationStop , closeSearchCallback}: CustomerSearchProps) => {
  const { currentLanguage } = useContext(LanguageContext)
  const [arSearchValue, setARSearchValue] = useState<string>('')  
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)
  const [expandedId, setExpandedId] = useState<number | null>(null)

  const lookupResult = useCustomerDetailsByStoreMultiple([arSearchValue], 1, 1, false)?.at(0)
  const customerDetails = lookupResult?.data?.commercialCustomerListInfo?.at(0)

  const saveCustomerHandler = () => {
    const selectedAddress: AddressInfo | undefined = customerDetails?.addressInfo?.find(address => address.addressId === selectedAddressId)

    const validDropoffSpot: DeliveryGeoCodes | undefined = !!selectedAddress ? selectedAddress.deliveryGeocodes?.find(dGeocode => {return dGeocode.isDefault === 'Y' && !!dGeocode.pinLabel}) : undefined

    const customerStop : TransferStop = {
      businessName: customerDetails?.customerInfo?.name || '', //SHOULD THIS BE LOCATION NAME because that makes the most sense for doordash??
      addressLine1: selectedAddress?.addressline1 || '',
      addressLine2: selectedAddress?.addressline2 || '',
      city: selectedAddress?.city || '',
      state: selectedAddress?.state || '',
      zipCode: selectedAddress?.zip || '',
      latitude: Number(!!validDropoffSpot ? validDropoffSpot.latitude : selectedAddress?.geocodes.latitude),
      longitude: Number(!!validDropoffSpot ? validDropoffSpot.longitude : selectedAddress?.geocodes.longitude),
      stopNotes: !!selectedAddress?.note ? selectedAddress.note + validDropoffSpot?.pinLabel : validDropoffSpot?.pinLabel || '', //CONFIRM THIS WITH PRODUCT
      phoneNumber: !!selectedAddress?.phoneNumber ? selectedAddress.phoneNumber : customerDetails?.customerInfo?.contactInfo?.at(0)?.phoneNumber?.at(0) || ''
    }
    setDestinationStop(customerStop)
    closeSearchCallback()
  }

  const searchCustomerDetails = () => {
    lookupResult?.refetch()
  }

  return (
    <div>
      <div style={TransferCustomerSearchStyles.rootContainer}>
        <div style={TransferCustomerSearchStyles.rootTitleContainer}>
          <Typography style={TransferCustomerSearchStyles.title}>
            {language[currentLanguage].customerSearch}
          </Typography>
          <IconButton aria-label="close" onClick={closeSearchCallback}>
            <CloseIcon sx={invoiceListStyles.closeIconImg} />
          </IconButton>
        </div>
        
        <div style={TransferCustomerSearchStyles.searchContainer} >
          <TextField
              data-testid="ar_number_input"
              label={(language as any)[currentLanguage].enterAccountNumber}
              variant="outlined"
              fullWidth
              margin="none"
              value={arSearchValue}
              error={lookupResult?.isError}
              helperText= 
                {lookupResult?.isError 
                  ? (lookupResult?.error?.response?.data?.message?.startsWith('404') 
                    ? (language as any)[currentLanguage].customerNotFound
                    : (language as any)[currentLanguage].apiError)
                  : ""}
              onChange={(event)=>setARSearchValue(event.target.value)}
          />
          <Button variant="contained" color="primary" style={TransferCustomerSearchStyles.searchButton} 
              data-testid="ar_number_search"
              disabled={!!arSearchValue && arSearchValue !== '' ? false : true}
              onClick={() => searchCustomerDetails()}
          >
              {(language as any)[currentLanguage].search}
          </Button>
        </div>

        <div style={TransferCustomerSearchStyles.rootBodyContainer}>
          {lookupResult?.isLoading && (
            <CircularProgress data-testid={'spinner'} size={100} thickness={2}/>
          )}

          {!!lookupResult && lookupResult.isSuccess && !!!customerDetails && 
            <>{language[currentLanguage].customerNotFound}</>
          }
          {!!lookupResult && !!customerDetails && 
            <div style={TransferCustomerSearchStyles.customerAddressContainer}>
              <div style={TransferCustomerSearchStyles.nameContainer}>
                <Typography style={TransferCustomerSearchStyles.name}>{customerDetails.customerInfo?.name}</Typography>
              </div>
              <div style={TransferCustomerSearchStyles.addressContainer}>
                {!!customerDetails.addressInfo && customerDetails.addressInfo.length > 0 ?
                  customerDetails?.addressInfo.map((address: AddressInfo, index: number) => {
                    const validDropoffSpot = address.deliveryGeocodes?.find(dGeocode => {return dGeocode.isDefault === 'Y' && !!dGeocode.pinLabel})
                    return <Box sx={TransferCustomerSearchStyles.location}>
                      <Radio style={TransferCustomerSearchStyles.locationButtons}
                        checked={selectedAddressId === address.addressId}
                        onChange={() => setSelectedAddressId(address.addressId)}
                        data-testid={address.addressLabel}
                      />
                      <Box style={TransferCustomerSearchStyles.locationDetails}>
                        {!!address.addressLabel && address.addressLabel !== '' && <Typography>{address.addressLabel}</Typography>}
                        <Typography style={TransferCustomerSearchStyles.locationInfo}>{address.addressline1} {!!address.addressline2 ? ", " + address.addressline2 : ""}</Typography>
                        <Typography style={TransferCustomerSearchStyles.locationInfo}>{`${address.city}, ${address.state}, ${address.zip}`}</Typography>
                        {index === expandedId && 
                          <Box>
                            {
                              !!validDropoffSpot && <Box sx={TransferCustomerSearchStyles.locationDropoffInfo}>
                                <PinIcon style={TransferCustomerSearchStyles.icons}/> 
                                <Box>
                                  <Typography>{(language as any)[currentLanguage].dropOffSpot}</Typography>
                                  <Typography style={TransferCustomerSearchStyles.locationInfo}>{validDropoffSpot.pinLabel}</Typography>
                                </Box> 
                              </Box>
                            }
                            {
                              !!address.note && <Box sx={TransferCustomerSearchStyles.locationDropoffInfo}>
                                <NotesIcon style={TransferCustomerSearchStyles.icons}/> 
                                <Box>
                                  <Typography>{(language as any)[currentLanguage].deliveryNotes}</Typography>
                                  <Typography style={TransferCustomerSearchStyles.locationInfo}>{address.note}</Typography>
                                </Box> 
                              </Box>
                            }
                          </Box>
                        }
                      </Box>
        
                      {(!!address.note || !!validDropoffSpot) && 
                        <Button style={TransferCustomerSearchStyles.locationButtons}
                          variant='text'
                          data-testid={address.addressId}
                          onClick={() => {index !== expandedId ? setExpandedId(index) : setExpandedId(null)}}>
                          {index === expandedId ? <KeyboardArrowUpSharp/> : <KeyboardArrowDownSharp />}
                        </Button>
                      }
                    
                    </Box>
                  })
                  : 
                  <>{language[currentLanguage].noLocationFound}</>
                }
              </div>
            </div>
          }
        </div>

        <div style={TransferCustomerSearchStyles.buttonContainer}>
          <Button
            variant="secondary"
            sx={TransferCustomerSearchStyles.buttonStyle}
            onClick={closeSearchCallback}>
            {language[currentLanguage].cancel}
          </Button>
          <Button
            variant="primary"
            onClick={saveCustomerHandler}
            sx={TransferCustomerSearchStyles.buttonStyle}
            disabled={!selectedAddressId}>
            {language[currentLanguage].save}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TransferCustomerSearch
