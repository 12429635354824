import { useContext } from 'react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { useQuery, useQueries } from 'react-query'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { CustomerService } from '../../utilities/services/CustomerService/CustomerService'

export const useCustomerDetailsByStore = (
  arNumber: any,
  page: number,
  pageSize: number,
  enabled: boolean = true,
) => {
  const { currentStore } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { addSnack } = useContext(SnackbarContext)
  return useQuery(
    [currentStore, arNumber, page, pageSize],
    (context) =>
      CustomerService.getCustomerDetails(
        context.queryKey[0], 
        context.queryKey[1],
        context.queryKey[2],
        context.queryKey[3],
        false
      ),
    {
      enabled:false,
      onError: (err: any) => {
        if (err.status !== 'NOT_FOUND')
          addSnack({
            severity: 'error',
            message: (language as any)[currentLanguage].errorCustomerDetails,
            duration: 3000
          })
      },
      retry: (failureCount, error: any) => failureCount < 3 && error.status === 500
    }
  )
}


export const useCustomerDetailsByStoreMultiple = (
  arNumbers: any[],
  page: number,
  pageSize: number,
  enabled: boolean = true,
) => {
  const { currentStore } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { addSnack } = useContext(SnackbarContext)
  return useQueries(
    arNumbers.map((arNumber) => ({
      queryKey: [currentStore, arNumber],
      queryFn: () =>
      CustomerService.getCustomerDetails(
        currentStore, 
        arNumber,
        page,
        pageSize,
        true
      ),
      enabled: enabled && !!arNumber,
      onError: (err: any) => {
        if (err.status !== 'NOT_FOUND')
          addSnack({
            severity: 'error',
            message: (language as any)[currentLanguage].errorCustomerDetails,
            duration: 3000
          })
      },
      retry: (failureCount: any, error: any) => failureCount < 3 && error.status === 500 && !error.message.startsWith("404"),
      staleTime: 300000 
    })),
  )
}
