import axiosInstance from '../helpers/AxiosHelper'

export type Vehicles = {
    make?: string
    vehicleName?: string
    vehicleVin: string
    year?: any
    model?: string
    licensePlate?: string
    color?: string
    lastOdometerReading?: any | null
    storeNumber?: string
    inspectionFrequency?: any | null
    createdBy?: string | undefined
    createdDate?: string
    obsoleteRecord?: Boolean
    lastInspectionDate?: string | null
    lastServiceDate?: string | null
}

export type AllVehicles = {
    vehicles: Vehicles[]
}

export class MyFleetService {
  static USER_ENDPOINT = '/vehicles'

  static async getAllVehicles(storeNumber: string): Promise<AllVehicles> {
    try {
      const response = await axiosInstance.get<AllVehicles>(
        `${this.USER_ENDPOINT}/storeNumber/${storeNumber}`
      )
      return response.data as AllVehicles
    } catch (error: any) {
      return Promise.reject(error.response)
    }
  }

  static async saveNewVehicle(vehicleRequest: Vehicles): Promise<Vehicles> {
    try {
      const response = await axiosInstance.post<Vehicles>(this.USER_ENDPOINT, vehicleRequest)
      return response.data
    } catch (error: any) {
      return Promise.reject(error.response?.data?.message)
    }
  }

  static async disableVehicle(vehicleVin: string): Promise<void> {
    try {
      await axiosInstance.patch(`${this.USER_ENDPOINT}/${vehicleVin}/disable`, null)
      return
    } catch (error: any) {
      return Promise.reject(error.response?.data?.message)
    }
  }

  static async updateVehicleDetails(vehicleRequest?: Vehicles): Promise<Vehicles> {
    try {
      const response = await axiosInstance.patch<Vehicles>(`${this.USER_ENDPOINT}`, vehicleRequest)
      return response.data
    } catch (error: any) {
      return Promise.reject(error.response?.data?.message)
    }
  }

  static async getInspectionReports(storeNumber?: string, vehicleVin?: string): Promise<any> {
    try {
      const response = await axiosInstance.get<any>(
        `${this.USER_ENDPOINT}/inspectionVehicleBrowser/${vehicleVin}`
      )
      return response.data
    } catch (error: any) {
      return Promise.reject(error.response?.data?.message || error.response)
    }
  }
}
