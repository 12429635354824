import Colors from '../../../../assets/Colors'

type TransferCustomerSearchStylesType = {
  rootContainer: React.CSSProperties
  rootTitleContainer: React.CSSProperties
  rootBodyContainer: React.CSSProperties
  searchContainer: React.CSSProperties
  buttonContainer: React.CSSProperties
  title: React.CSSProperties
  searchButton: React.CSSProperties
  customerAddressContainer: React.CSSProperties
  nameContainer: React.CSSProperties
  addressContainer: React.CSSProperties
  name: React.CSSProperties
  location: React.CSSProperties
  locationButtons: React.CSSProperties
  locationDetails: React.CSSProperties
  locationInfo: React.CSSProperties
  locationDropoffInfo: React.CSSProperties
  buttonStyle: React.CSSProperties
  icons: React.CSSProperties
}

export const TransferCustomerSearchStyles:TransferCustomerSearchStylesType = {
  rootContainer: {
    backgroundColor: Colors.white,
    borderRadius: '3px',
    width: '480px',
    height: '499px',
    position: 'relative'
  },
  rootTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rootBodyContainer: {
    display: 'flex',
    height: '330px',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    justifyContent: 'space-around'
  },
  buttonContainer: { 
    display: 'flex', 
    justifyContent: 'end', 
    position: 'absolute', 
    bottom: '0px', 
    right: '0px'
  },
  title: {
    fontSize: '22px',
    color: Colors.napaBlue
  },
  searchButton: {
    height: '55px', 
    width: '150px'
  },
  customerAddressContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  nameContainer: { 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignSelf: 'center', 
    color: Colors.napaBlue, 
    fontWeight: 700, 
    padding: '8px 4px 4px'
  },
  addressContainer: {
    overflowY: 'scroll',
    height: '295px',
    gap: '5px',
    display: 'flex',
    flexDirection: 'column'
  },
  name: {fontSize: '1.3rem'},
  location: {
    border: '1px solid #ADADAD',
    borderRadius: '3px',
    padding: '10px 0px',
    display: 'flex',
    flexDirection: 'row'
  },
  locationButtons: {
    height: '35px', 
    margin: '8px'
  },
  locationDetails: {
    display: 'flex', 
    flexDirection: 'column', 
    flexGrow: 1
  },
  locationInfo: {
    color: '#4A4A4A'
  },
  locationDropoffInfo: {
    padding: '4px',
    display: 'flex',
    flexDirection: 'row'
  },
  buttonStyle: { 
    width: 'auto', 
    height: 'auto', 
    margin: '9px' 
  },
  icons: {
    width: '18px',
    height: '18px',
    paddingTop: '4px'
  }
}
