import { LanguageValues } from './LanguageTypes'

export const frenchLanguageValues: LanguageValues = {
  add: 'Ajouter',
  invoiceExample: 'ex. : 12345-1234',
  confirmPassword: 'Confirmer le mot de passe',
  route: 'route',
  startDate: 'Date de début',
  invoiceNumber: 'Fact. #',
  itemNumber: 'ARTICLE #',
  lineAbbreviation: 'LIGNE',
  description: 'DESCRIPTION',
  quantity: 'QTÉ',
  routeNumber: 'Route #',
  yourStores: 'Vos magasins',
  storeNumber: 'Magasin #',
  completed: 'Complété',
  addInvoice: 'Ajouter (la) facture',
  totalStops: "Nombre total d'arrêts :",
  cancelRoute: 'Annuler la route',
  no: 'Non',
  yes: 'Oui',
  cancel: 'Annuler',
  cancelDoordash: 'Annuler la Doordash',
  signature: 'Signature',
  deliveryPhoto: 'Photo de la livraison',
  address: 'Adresse',
  ok: 'OK',
  invoice: 'Facture #',
  invoiceTitle: 'Factures',
  routesTitle: 'Routes',
  delivered: 'Livré: ',
  deliveredAt: 'Livré à',
  editAllInvoice: 'Modifier toutes les factures pour',
  remove: 'Retirer',
  removeInvoice: 'Retirer facture',
  editInvoice: 'Modifier facture(s)',
  back: 'Précédent',
  complete: 'Complété',
  done: 'Fait',
  termsAndConditions: 'Conditions générales',
  lastUpdated: 'Dernière mise à jour : 8 mars 2023',
  decline: 'Refuser',
  accept: 'Accepter',
  createPassword: 'Créez un mot de passe',
  passwordRequirements: 'Exigences du mot de passe',
  alphaNumeric: 'Inclure au moins (1) chiffre, une (1) majuscule et une (1) minuscule.',
  createSignInPassword: 'Créer un mot de passe et se connecter',
  resetPassword: 'Réinitialiser le mot de passe',
  authenticationCode: "Entrez le code d'authentification qui a été envoyé à",
  sendAnotherCode: 'Envoyer un autre code',
  resetMyPassword: 'Réinitialiser mon mot de passe',
  forgotYourPassword: 'Mot de passe oublié?',
  save: 'Sauvegarder',
  completeRegistration: "Terminer l'inscription",
  tempPassword:
    "Entrez le mot de passe temporaire envoyé à l'adresse courriel fournie à l’équipe NSSA.",
  register: "S'inscrire",
  signIn: 'Connexion',
  keepMeSignIn: 'Conserver ma session active.',
  newNAPA: 'Nouveau dans le système magasins NAPA',
  addUser: "Ajouter l'utilisateur",
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  email: 'Courriel',
  confirm: 'Confirmer',
  editUser: "Modifier l'usager",
  status: 'État',
  actions: 'Actions',
  admin: 'Admin',
  disabled: 'Désactivé',
  edit: 'Modifier',
  disable: 'Désactiver',
  enable: 'Activer',
  signOut: 'Déconnexion',
  signOutDesc: 'Êtes-vous sûr(e) de vouloir vous déconnecter?',
  signOutText: 'DÉCONNEXION',
  cancelText: 'ANNULER',
  storeSupport: 'Support magasin',
  contactUs: 'Veuillez communiquer avec nous à',
  contactUsapac: `Pour obtenir de l'aide avec cet appareil, veuillez appeler`,
  urgentCalls: 'Pour les problèmes NSSA, veuillez nous contacter à ',
  supportMail: 'Support magasin',
  storeSystems: 'SYSTÈMES MAGASINS',
  genuineDelivery: 'Livraison authentique',
  update: 'Mise à jour',
  updateRoute: 'Mettre à jour la route',
  storeSystemsTitleCase: 'Systèmes magasins',
  dvir: 'RINV',
  errorMessage: "Une erreur s'est produite. Veuillez recommencer.",
  noVehicles: 'Aucun véhicule disponible',
  red: 'Rouge',
  yellow: 'Jaune',
  green: 'Vert',
  odometer: 'Odomètre',
  odometerPlaceholder: 'ex. : 123456789',
  close: 'Fermer',
  checkEngineLight: 'Vérifier le voyant du moteur',
  inspectionCompletedBy: 'Inspection effectuée par :',
  noInspectionCompleted: "Aucune inspection n'a été effectuée",
  completedOn: 'Effectué le :',
  warning: 'Avertissement :',
  error: 'erreur',
  success: 'succès',
  notes: 'Notes',
  comment: 'Commentaire',
  optional: 'Optionnel',
  required: 'Requis',
  myFleet: 'Mes véhicules',
  currentStore: 'Magasin sélectionné',
  selectStore: 'Sélectionnez le magasin',
  errorDeliveryRoutes: 'Erreur dans la récupération des routes de livraison',
  errorUserRoutes: "Erreur dans la récupération des routes de livraison de l'utilisateur",
  errorInvoice: 'Erreur dans la récupération des factures',
  errorInspectionReport: "Erreur dans la récupération des rapports d'inspection",
  errorVehicles: 'Erreur dans la récupération des véhicules',
  errorTnC: 'Erreur dans la récupération des conditions générales les plus récentes',
  errorUserTnC: "Erreurs dans la récupération des conditions générales de l'utilisateur",
  errorActiveDrivers: 'Erreur dans la récupération des livreurs actifs',
  errorUsers: 'Erreur dans la récupération des usagers',
  errorSignOut: 'Erreur de déconnexion',
  errorEmail:
    "Courriel ou nom d'utilisateur incorrect. Veuillez communiquer avec votre administrateur",
  contactAdmin: 'Permissions insuffisantes. Veuillez communiquer avec votre administrateur.',
  incorrectPassword:
    "Nom d'utilisateur et/ou mot de passe incorrect. Veuillez réessayer ou réinitialiser votre mot de passe.",
  accountDisabled:
    "Ce compte a été désactivé. Veuillez consulter l'administrateur du magasin pour le réactiver.",
  tryAgainOrReset:
    'Veuillez essayer plus tard ou cliquez ici pour réinitialiser votre mot de passe.',
  serviceError: 'Erreur de service inconnue',
  errorAuthentication: "Aucun utilisateur n'a été authentifié.",
  cognito: 'Erreur de déconnexion "of cognito"',
  unknownError: 'Erreur inconnue. Réessayez plus tard.',
  mailSentToMail: 'Succès! Un lien a été envoyé au courriel existant dans notre système.',
  associatedUser: "Entrez le nom d'utilisateur associé à votre compte",
  sendEmail: 'Envoyer courriel',
  buildRoute: 'pour créer une route',
  routeBuilder: 'Créateur de route',
  errorVehicle: 'Nom du véhicule requis.',
  errorYear: 'Année requise.',
  errorMake: 'Marque requise.',
  errorModel: 'Modèle requis.',
  errorVin: 'NIV requis',
  errorLicense: "Plaque d'immatriculation requise",
  errorColor: 'Couleur du véhicule requise',
  successVehicle: 'Succès! Véhicule ajouté',
  successUpdated: 'Succès! Véhicule mis à jour',
  successDisabled: 'Succès! Véhicule modifié',
  addVehicle: 'Ajouter véhicule',
  vehicle: 'Nom du véhicule (requis)',
  ymmc: 'Année / Marque / Modèle / Couleur (requis)',
  vin: 'NIV (requis)',
  license: "Plaque d'immatriculation (requise)",
  odometerOptional: 'Odomètre (optionnel)',
  lastInspection: 'Dernière inspection',
  inspectionReport: "Rapports d'inspection",
  searchInspection: 'Rechercher par date, effectué par, ou odomètre',
  result: 'Résultats pour',
  redMessage: 'Requiert une attention immédiate',
  yellowMessage: "Pourrait nécessiter une attention particulière à l'avenir",
  viewReport: 'Voir le rapport',
  disableVehicle: 'Désactiver le véhicule',
  confirmDisable: 'êtes vous certain de vouloir déactiver le nom du véhicule',
  searchVehicles: 'Rechercher par nom de véhicule, marque, modèle ou avec un autre mot-clé',
  newUser: 'Nouvel utilisateur',
  existingAccount: 'Vous avez déjà un compte?',
  successPassword: 'Succès! Votre mot de passe a été réinitialisé',
  preparingSomething: 'Préparez-vous à être agréablement surpris!',
  inProgress: 'En construction',
  routeCreated: 'a été créé.',
  errorRouteCreation: 'Erreur sur la création de la route; actualisez la page et réessayez',
  username: "Veuillez entrer une adresse ou un nom d'utilisateur valide",
  password: 'Veuillez entrer un mot de passe',
  myDrivers: 'Mes livreurs',
  import: 'Importer',
  whiteboard: 'Tabeau blanc',
  reports: 'Rapports',
  createdAt: 'Livraison créée à',
  totalInvoices: 'Total des factures',
  errorRouteData: 'Erreur à la récupération des données de route',
  noRoutes: 'Aucune route active',
  map: 'Carte',
  userDisabled: 'Utilisateur désactivé',
  fName: 'Prénom requis.',
  lName: 'Nom de famille requis.',
  validEmail: 'Veuillez entrer une adresse courriel valide',
  validUser: "Veuillez entrer un nom d'utilisateur valide",
  invalidPhone: 'Numéro de téléphone invalide',
  selectDefaultStore: 'Veuillez sélectionner un magasin par défaut.',
  userType: "Veuillez sélectionner un type d'utilisateur.",
  errorDriver: "Erreur à la création d'un nouveau livreur",
  emailFirst: 'Succès! Un courriel a été envoyé à',
  emailSecond: "pour terminer l'inscription",
  userExist: "Le nom d'utilisateur est déjà pris",
  defaultStore: 'Sélectionnez le magasin par défaut',
  allStores: 'Sélectionnez tous les magasin(s)',
  userTypeTitle: "Type d'utilisateur",
  driver: 'Livreur',
  infoText: 'Vous pouvez utiliser des lettres, des chiffres et les symboles suivants : ! @  #   .  ',
  notUnique: "Les adresses courriels n'ont pas besoin d'être uniques!",
  newDriver: 'Créer nouveau livreur',
  store: 'Magasin par défaut',
  csvUpload: 'Sélectionner un fichier CSV à télécharger',
  drag: 'ou le glisser et le déposer ici',
  importUser: 'Importer des utilisateurs',
  csvFormat:
    'Téléchargez jusqu’à XX livreurs en même temps dans un fichier CSV qui inclut au moins le nom, le courriel et le magasin par défaut de chaque livreur.',
  downloadFormat: 'Téléchargez notre exemple de fichier en CSV',
  userDisable: "L'utilisateur est désactivé.",
  errorDisable: "Erreur à la désactivation de l'utilisateur",
  userEnabled: "L'utilisateur est activé.",
  errorEnable: "Erreur à l'activation de l'utilisateur",
  enabled: 'Êtes-vous certain que vous voulez ',
  invoices: 'Facture(s)',
  errorUpdateDriver: "Erreur lors de la mise à jour de l'information sur le livreur",
  assignedStore: 'Magasins attribués',
  selectStoreTitle: 'Sélectionnez les magasins',
  phoneNumber: 'Numéro de téléphone',
  enableUser: 'Activer l’utilisateur ',
  disableUser: 'Désactiver l’utilisateur',
  statusDelivered: 'Livré',
  statusInprogress: 'En cours',
  statusPending: 'Attribué',
  statusDone: 'Complété',
  searchRoute: 'Rechercher/filtrer par # de route, de livreur, statut, de client, numéro de facture ou numéro de pièce',
  editRoute: 'Modifier la route',
  viewDetails: 'Voir les détails',
  zeroRoutes: 'Aucune route disponible.',
  pdf: 'Télécharger en PDF',
  print: 'Imprimer votre nom',
  noInvoice: 'Aucune facture sélectionnée',
  none: 'Aucun',
  validInvoice: 'Veuillez saisir un numéro de facture valide',
  invoiceAdded: 'a été ajouté.',
  reset: 'Réinitialisation',
  searchInvoice: 'Recherche par # De Facture, Par Client, Par # pièce ou Par Description de pièce',
  duplicateDoordash:
    "Doordash ne peut pas créer un autre route avec un identifiant déjà existant. Vous devez créer une nouvelle route pour l'expédition avec Doordash ",
  thirdParty: 'Livraison par une tierce partie',
  deliveryCost: 'Frais de livraison : ',
  deliveryHandling: 'Gestion des livraisons',
  acceptQuote: 'Accepter la soumission',
  errorDoordash:
    'Erreur lors de la création du devis Doordash - la route peut encore être attribuée à un chauffeur ',
  errorDistance: 'La distance autorisée entre les adresses est dépassée',
  invalidNumber: 'Le numéro de téléphone du client sur la facture est invalide',
  errorUpdate: 'Erreur lors de la mise à jour de la route, actualisez la page et réessayez. ',
  launch: 'Lancer la livraison par un sous-traitant',
  saveChanges: 'Sauvegarder les modifications',
  errorStops: 'Trop d’arrêts. Veuillez réessayer. (Maximum autorisé : 24 factures).',
  errorCancel: 'Erreur à l’annulation de la route.',
  errorCancelling:
    'La livraison ne peut pas être annulée ou mise à jour après l’attribution à une tierce partie',
  cancelModal: 'Êtes-vous certain de vouloir annuler la route ?',
  userManagement: 'Gestion des utilisateurs',
  usernameText: "Nom d'utilisateur",
  passwordText: 'Mot de passe',
  emailUsername: "Courriel / Nom d'utilisateur",
  temporaryPassword: 'Mot de passe temporaire',
  codeText: 'Code d’authentification',
  newPassword: 'Nouveau mot de passe',
  charatersLongText: 'Les mots de passe doivent contenir au moins 8 caractères',
  showMap: 'Afficher la carte',
  hideMap: 'Masquer la carte',
  customerText: 'CLIENT',
  orderedAt: 'Commandé À',
  timeElapsed: 'Temps Écoulé',
  priorityInMins: 'Niveau De Service',
  endDate: 'Date de fin',
  rowsPerPage: 'Lignes par page',
  ofText: '1-16 de 16',
  noResults: 'Aucun résultat trouvé.',
  filtersText: 'Filtres',
  lastUpdatedHeader: 'DERNIÈRE MISE À JOUR',
  errorFindInvoice: 'Facture #XXX du magasin #915202200 non trouvée dans le domaine de facturation',
  createRoute: 'Créer la route',
  addInvoices: 'Ajouter les factures',
  noInvoices: 'Aucune facture',
  saveInvoices: 'Sauvegarder les factures',
  successRoute: 'Succès! Route',
  hasCancel: 'annulée.',
  pickupETA: 'Temps estimé de ramassage',
  deliveryETA: 'Temps estimé de livraison',
  pickedUp: 'Ramassé: ',
  pickNote: 'Note de ramassage (optionnel)',
  deliveryNote: 'Note de livraison (optionnel)',
  hasUpdated: 'mise à jour.',
  nameText: 'Nom',
  selectAll: 'Tout sélectionner',
  searchStore: 'Rechercher le magasin',
  toText: 'à',
  vehicleName: 'Nom du véhicule',
  ymmcHeader: 'A/M/M/C',
  licensePlate: "Plaque d'immatriculation",
  ymmcTitle: 'AMMC',
  byText: 'par',
  dateText: 'DATE',
  completedBy: 'EFFECTUÉ PAR',
  vinNumber: 'NIV*',
  vinText: 'NIV',
  whiteboardText: 'Tableau blanc',
  changeYourPassword: 'pour changer votre mot de passe',
  selectType: "Sélectionnez le type d'utilisateur",
  year: 'Année',
  make: 'Marque',
  model: 'Modèle',
  color: 'Couleur',
  inspectionRequired: 'Inspection requise',
  invalidCode: "Code non valide. S'il vous plaît, réessayez",
  estimatedTime: 'Heure de ramassage estimée',
  dlvr: 'Livr',
  created: 'Créée',
  pending: 'En attente',
  enRoute: 'En Route',
  resetAll: 'Tout réinitialiser',
  selectRange1: 'There are no routes available.',
  selectRange2: 'Select a date range or reset to see routes.',
  returnToStore: 'Retour au magasin',
  arrivedAt: 'Arrivé à',
  editRouteWarningMessage:
    'Les modifications n’ont pas été enregistrées. Cliquez sur « Mettre à jour la route » pour les enregistrer, ou fermez la fenêtre pour les annuler.',
  releaseHold: 'Relâcher',
  placeHold: 'Suspendre',
  markDelivered: 'Marquer comme envoyé',
  invoiceFlagged: 'Signalé',
  onHold: 'En suspens',
  successInvoice: 'Succès! La facture #',
  invoiceDeliveryMsg: 'Veuillez sélectionner une raison pour laquelle la facture est marquée comme livrée. La facture ne sera plus affichée, mais vous pourrez toujours la consulter dans TAMS.',
  reason: 'Raison (obligatoire)',
  salesReason: 'Livraison par la Direction / le représentant',
  untrainedReason: 'Messager / chauffeur non entraîné',
  invoiceDelivered: 'a été marquée comme livrée.',
  invoiceFailed: 'Désolé! Nous n’avons pas pu marquer cette facture comme livrée. Veuillez réessayer.',
  invoiceOnHold: 'a été mise en attente.',
  invoiceRemoved: 'a été supprimée.',
  deliveredBy: 'Livré par (obligatoire):',
  ddUrgentCall: 'En cas de problèmes de livraison urgents, veuillez appeler le ',
  doordashDriver: 'Chauffeur: ',
  doordashContact:
    'En cas de problèmes de livraison avec DoorDash (pièces perdues, problème concernant un « dasher » etc.), communiquez avec le Service d’assistance à ',
  doordashPhoneNumber: 'Téléphone: ',
  estimatedDelivered: 'Temps estimé de livraison: ',
  estimatedPickUp: 'Temps estimé de ramassage: ',
  search: 'Rechercher',
  trackMyOrder: 'Suivre ma commande',
  actualRoute: 'Route actuelle',
  suggestedRoute: 'Route suggérée',
  backToStoreRoutes: 'Retour aux routes du magasin',
  gpsDisclaimer: '*Les chauffeurs utilisent un GPS pouvant faire en sorte que la route réelle diffère de la route suggérée créée',
  buildRouteText: 'Créer route',
  unassignedInvoice: 'Factures non assignées',
  holdInvoice: 'Factures en suspens',
  removeInvoicemsg: 'Retirer des factures disponibles pour livraison. Vous pourrez toujours consulter cette facture dans TAMS.',
  canceledReason: 'Customer canceled',
  systemReason: 'Phone / system issue',
  pickUpReason: 'Picked up in-store',
  errorRemovingInvoice: 'Désolé, nous n’avons pu supprimer cette facture. Veuillez réessayer.',

  //TODO: Translate these properly, used Google Translate for these values currently displayed:
  invoiceHoldNote: 'Remarque: Les factures en attente ne sont pas affichées ci-dessus',
  onHoldTooltip: "Impossible de créer un itinéraire avec des commandes en attente",
  errorRouteCreationInvoiceOnHold: 'Une ou plusieurs des factures sélectionnées sont en attente.',
  errorFeatureConfiguration: "Error retrieving feature configuration",
  stopAutoDispatched: `'Succès! La répartition automatique a été interrompue pour la facture`,
  autoDispatched: `Succès! Le # d'identification de la route DoorDash a été créée.`,
  autoDispatchedError: `Désolé ! Répartition automatique impossible de la facture. Veuillez réessayer.`,
  stopAutoDispatchedError: `Désolé ! Impossible d'interrompre la répartition automatique de la facture.`,
  autoDispatchNow: `Répartir automatiquement maintenant`,
  toTryAgainOrStopAutoDispatchBelow: `pour réessayer ou arrêter l'envoi automatique ci-dessous`,
  dispatchingNow: 'Expédition maintenant',
  pleaseClick: 'Veuillez cliquer sur',
  stopDispatchNow: `Interrompre la répartition automatique`,
  toTryAgain: 'pour réessayer',
  selectToRemoveTheAutoDispatch: 'Sélectionnez la suppression de la minuterie de répartition automatique pour cette facture. Redémarrage impossible.',
  reasonRequired: 'Raison (obligatoire)',
  selectReason: 'Sélectionnez la raison',
  autoDispatching: 'RÉPARTITION AUTOMATIQUE',
  autoDispatchIn: 'Répartir automatiquement dans ',
  dispatchError: 'Erreur de répartition',
  autoDispatchedRoutes: 'Routes de répartition automatique',
  stopAutoDispatchDialogSubText: `Sélectionnez une raison pour l'interruption de la répartition automatique. La minuterie de répartition automatique sera supprimée et ne pourra être redémarrée.`,
  reasonCustomerAddress: 'Addresse du client difficile à trouver',
  reasonCashCustomer: "Client au comptant",
  reasonHazardMaterials: "Matières dangereuses",
  reasonTransferPart: "Transférer la pièce (Pièce de transfert ou à transférer)",
  reasonPartSpecs: "'La pièce ne respecte pas les critères de dimension",
  subReasnPartSpecs: "'c.-à-d. plus de 60 livres (20 kg ou plus de 152.4 cm)",
  reasonSystemError: "Erreur de système",
  autoDispatchColumn: "Répartition-automatique",
  errorUserByUserName: "Veuillez contacter l'administrateur du magasin pour être ajouté au magasin",
  addStop: 'Ajouter un arrêt',
  customStop: 'Add Custom Stop', //TODO
  customerMgmt: "Gestion des clients",
  customerMgmtSearchHeader: "Rechercher un client",
  customerSearch: "Recherche de clients",
  customerNotFound: "client introuvable",
  noLocationFound: "Aucun emplacement trouvé",
  apiError: "erreur de service",
  enterAccountNumber: "Entrer le numéro de compte (CC#)",
  customerMgmtSearchSubText: "Saisissez le numéro AR d'un client pour afficher les détails de son compte ou mettre à jour ses informations de livraison. Vous pouvez retrouver ce numéro sur la facture du client ou dans TAMS.",
  arNumber: "Numéro AR",
  ar: "AR #",
  phone: "Téléphone",
  dropOffSpot: "Lieu de dépôt",
  noResultsCustomerView: "Aucune donnée trouvée. Veuillez utiliser le champ de recherche à gauche pour charger les résultats.",
  errorCustomerDetails: "Erreur lors de la récupération des détails du client",
  location: "Emplacement",
  defaultLocation: 'Emplacement par défaut',
  backOfStore: "Arrière du magasin",
  deliveryNotes: "Bons de livraison",
  locationName: "Nom de l'emplacement",
  addressLine: "Ligne d'adresse",
  optionalAddress: "+ Appartement, Suite, etc... (facultatif)",
  city: "Ville",
  state: "État",
  zipCode: "Code postal",
  dropOffSameLocation: "Lieu de dépôt identique à l'adresse de localisation",
  dropPinMapDesc: "Cliquez et faites glisser l'épingle rouge sur la carte ci-dessus pour déplacer le point de dépôt",
  coordinates: "Coordonnées",
  dropOffName: "Nom du dépôt",
  delete: "Supprimer",
  backToResults : "Retour aux résultats",
  accountDetails : "Détails du compte",
  serviceLevel : "Niveau de service",
  deliveryInformation : "Informations de livraison",
  additionalLocation: "Ajouter un emplacement supplémentaire",
  addLocation: "Ajouter un emplacement",
  insertInvoice: "Insérer une facture",
  moreActions: "Plus de mesures",
  cos: "Magasins corporatifs",
  iso: "Magasins associés",
  deliveryLocation:"Lieu de livraison",
  addToRoute: "Ajouter à l'itinéraire",
  addressLine1: 'Address line 1',
  stopName: "Nom de l'arrêt",
  newAddress: 'Nouvelle adresse',

  //TODO: Translate these:
  thirdPartyTransfer: 'Third-Party Transfer',
  source: 'Source',
  destination: 'Destination',
  select: 'Select',
  buildTransfer: 'Build Transfer',
  storeString: 'Store',
  customer: 'Customer',
  thirdPartyTransfers: 'Third-Party Transfers',
}
