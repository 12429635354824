import {
  Button,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu
} from '@mui/material'
import { ArrowForward, Clear, Warning } from '@mui/icons-material'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CompressOutlinedIcon from '@mui/icons-material/CompressOutlined'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import React, { useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  CustomStopDetail,
  DeliveryRoute,
  Invoice,
  InvoicesResponse,
  Stop
} from '../../../../utilities/types/DeliveryRouteTypes'
import { checkBackToStoreStop } from '../../../../utilities/helpers/checkStopTypes'
import { DeliveryRouteService } from '../../../../utilities/services/DeliveryRouteService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { convertEpochToTimezoneFormat } from '../../../../utilities/helpers/DateTimeFormatters'
import StrictModeDroppable from '../../../../utilities/hooks/StrictModeDroppable'
import { useUsersByStore } from '../../../../queries/Users/useUsersByStore'
import ExternalDelivery from '../ExternalDelivery/ExternalDelivery'
import CreateRoutePreviewMap from '../CreateRoute/CreateRoutePreviewMap'
import language from '../../../../language/language'
import Colors from '../../../../assets/Colors'
import { editRouteStyles } from './editRouteStyles'
import { isApac, isNonApac } from '../../../../utilities/helpers/RegionHelper'
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { ReactComponent as RedIcon } from '../../../../assets/dropPin.svg'

type EditRouteProps = {
  editAddInvoiceData: InvoicesResponse[]
  setEditAddInvoiceData: (editAddInvoiceData: any[]) => void
  setEditedRouteData: (routeDetails: any) => void
  setOpenAddInvoices: (openAddInvoices: boolean) => void
  editRemoveInvoices: Invoice[]
  setEditRemoveInvoices: (editRemoveInvoices: Invoice[]) => void
  route: DeliveryRoute | null
  setRoute: (route: any) => void
  selectedDriverId: string | null
  setSelectedDriverId: (selectedDriverId: string) => void
  editAddInvoices: string[]
  routeDetails: DeliveryRoute | null
  clearEditedRouteData: () => void
  openEditRoute: boolean
  setOpenEditRoute: (openEditRoute: boolean) => void
  refetchRoutes: any
  isFetchingRoutes: any
  setOpenCancelRoute: (openCancelRoute: boolean) => void
  getMapRouteCoordinates: (coordinates: string) => any
  setOpenCustomStop: (value: boolean) => void,
  customStopDetails: CustomStopDetail[],
  setCustomStopDetails: (detail: CustomStopDetail[]) => void
  setIsEditModal: (value: boolean) => void
}
const EditRoute = ({
  editAddInvoiceData,
  setEditAddInvoiceData,
  setEditedRouteData,
  setOpenAddInvoices,
  editRemoveInvoices,
  setEditRemoveInvoices,
  route,
  setRoute,
  selectedDriverId,
  setSelectedDriverId,
  editAddInvoices,
  routeDetails,
  clearEditedRouteData,
  openEditRoute,
  setOpenEditRoute,
  refetchRoutes,
  isFetchingRoutes,
  setOpenCancelRoute,
  getMapRouteCoordinates,
  setOpenCustomStop,
  customStopDetails,
  setCustomStopDetails,
  setIsEditModal
}: EditRouteProps) => {
  const { data: drivers } = useUsersByStore()
  const { addSnack } = useContext(SnackbarContext)
  const { storeAddress, currentStore, isDoordashEnabled } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const [createQuoteResponse, setCreateQuoteResponse] = useState<any>(null)
  const [disableThirdParty, setDisableThirdParty] = useState<boolean>(false)
  const [driverIdDuringPopup, setDriverIdDuringPopup] = useState<string>('None')
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const [reorderList, setReorderList] = useState<Stop[]>()
  const [showMap, setShowMap] = useState<boolean>(true)
  const routeETA = routeDetails?.stops?.reduce((acc, obj) => acc + (obj['expectedDriveDurationMinutes'] ?? 0), 0);
  const { isCustomStopEnabled, isCustomStopEnabledApac } = useFlags()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [removeCustomStopId, setRemoveCustomStopId] = useState<number[]>([])

  const { isCompanyOwned } = useContext(StoreContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenu = () => {
    setAnchorEl(null);
  }

  const handleAddInvoice = () => {
    setOpenAddInvoices(true)
    handleMenu()
  }

  const handleCustomStop = () => {
    setIsEditModal(true)
    setOpenCustomStop(true)
    handleMenu()
  }

  const handleCreateQuote = async () => {
    try {
      setDisableThirdParty(true)
      const customerAddress = routeDetails?.stops[0]?.invoices[0]?.deliveryAddress
      const request = {
        externalDeliveryId: `${routeDetails?.routeId}`,
        pickupAddress: `${storeAddress.addressLine1} ${storeAddress.addressLine2} ${storeAddress.city}, ${storeAddress.state} ${storeAddress.zipCode}`,
        dropoffAddress: `${customerAddress?.addressLine1} ${!!customerAddress?.addressLine2 ? customerAddress.addressLine2 : ''} ${customerAddress?.city}, ${customerAddress?.state} ${customerAddress?.zipCode}`,
        dropoffPhoneNumber: `${customerAddress?.phoneNumber}`,
        orderValueInCents: 0,
        storeNumber: currentStore,
        dropoffBusinessName: routeDetails?.stops[0]?.invoices[0].deliveryAddress.name || ''
      }
      const response: any = await DeliveryRouteService.createExternalDelivery(
        request,
        currentStore,
        routeDetails?.routeId as string
      )
      setCreateQuoteResponse(response)
      setDisableThirdParty(false)
    } catch (e: any) {
      var mes = ''
      if (e.message.includes('duplicate_delivery_id'))
        mes = (language as any)[currentLanguage].duplicateDoordash
      if (e.message.includes('distance_too_long'))
        mes = (language as any)[currentLanguage].errorDistance
      if (e.message.includes('dropoffPhoneNumber') || e.message.includes('dropoff_phone_number'))
        mes = (language as any)[currentLanguage].invalidNumber
      addSnack({
        severity: 'error',
        message: mes ? mes : (language as any)[currentLanguage].errorDoordash,
        duration: 3000
      })
      setDisableThirdParty(false)
    }
  }

  const handleClose = () => {
    setOpenEditRoute(false)
    setIsEditModal(false)
    setCreateQuoteResponse(null)
    setDriverIdDuringPopup('None')
    setEditAddInvoiceData([])
    setEditRemoveInvoices([])
    setRoute(null)
    setReorderList([])
    setShowMap(true)
  }

  // Keeps track of added invoices and selectedDriverId changes to display updateRoute disclaimer.
  useEffect(() => {
    if (routeDetails?.deliveryDriver) {
      setDriverIdDuringPopup(routeDetails.deliveryDriver)
    } else {
      setDriverIdDuringPopup('None')
    }
    if (routeDetails)
      setReorderList(routeDetails?.stops)
    else if (route)
      setReorderList(route?.stops)

    setShowWarning(
      editAddInvoiceData?.length !== 0 ||
      selectedDriverId !== driverIdDuringPopup ||
      editRemoveInvoices.length !== 0 ||
      customStopDetails?.length !== 0 ||
      removeCustomStopId.length !== 0
    )
  }, [
    customStopDetails,
    removeCustomStopId,
    editAddInvoiceData,
    selectedDriverId,
    driverIdDuringPopup,
    routeDetails,
    editRemoveInvoices,
    route
  ])

  const handleBackArrow = () => {
    setCreateQuoteResponse(null)
  }

  const handleChangeDriver = (e: any) => {
    setSelectedDriverId(e.target.value)
  }

  const selectDriver = () => {
    return (
      <Grid item xs={12} sx={[editRouteStyles.driversFormGrid, { paddingLeft: 3 }]}>
        <FormControl sx={editRouteStyles.driversForm}>
          <Typography sx={editRouteStyles.driverType}>
            {(language as any)[currentLanguage].driver}*
          </Typography>
          <Select
            id="drivers_list"
            inputProps={{ 'data-testid': 'user-type-select' }}
            displayEmpty
            value={selectedDriverId}
            required={true}
            onChange={handleChangeDriver}
            placeholder={(language as any)[currentLanguage].selectType}
            style={{ maxHeight: '40px' }}>
            <MenuItem value="None">
              <em>{(language as any)[currentLanguage].none}</em>
            </MenuItem>
            {drivers &&
              drivers?.map((driver) => {
                return (
                  <MenuItem key={driver.username} value={driver.username}>
                    <em>
                      {driver.givenName} {driver.familyName}
                    </em>
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      </Grid>
    )
  }

  const renderInvoiceAndPO = (item: any) => {
    return (
      <Grid container columns={{ xs: 12, md: 44 }}>
        <Grid item xs={12}>
          <AllItemText sx={editRouteStyles.invoiceNumber}>{`${(language as any)[currentLanguage].invoiceNumber
            }${item.invoiceNumber}`}</AllItemText>
        </Grid>
      </Grid>
    )
  }

  const ItemHeader = () => {
    return (
      <Box>
        <Grid container spacing={1} paddingBottom={0}>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].itemNumber}
            </AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].lineAbbreviation}
            </AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].description}
            </AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].quantity}
            </AllItemText>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const Item = (item: any) => {
    return (
      <Box>
        <Grid container spacing={1} paddingBottom={1}>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>{item.part.partNumber}</AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>{item.part.lineAbbreviation}</AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>{item.part.description}</AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={editRouteStyles.headerTitle}>
              {item.part.requestedQuantity}
            </AllItemText>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const Notes = ({ notes }: any) => {
    return (
      <Box>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item xs={12}>
            <AllItemText sx={editRouteStyles.notesTitle}>
              {(language as any)[currentLanguage].notes}:
            </AllItemText>
            <Typography
              sx={{
                color: Colors.napaBlack,
                fontSize: '12px',
                width: '100%'
              }}>
              {notes}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const errorModal = () => {
    addSnack({
      severity: 'error',
      message: (language as any)[currentLanguage].errorUpdate,
      duration: 3000,
      action: { prompt: 'REFRESH', callback: () => window.location.reload() }
    })
  }

  const successModal = () => {
    addSnack({
      severity: 'success',
      message: `${(language as any)[currentLanguage].successRoute} #${routeDetails?.routeId} ${(language as any)[currentLanguage].hasUpdated
        }`,
      action: null,
      duration: 3000
    })
  }

  const handleRemoveInvoice = async (invoiceNumber: string) => {
    if (routeDetails) {
      if (routeDetails.stops.length === 2 && routeDetails.stops[0].invoices.length === 1) {
        setOpenCancelRoute(true)
        setOpenEditRoute(false)
      } else {
        // pushing the entire invoice response so that it can be utilized to create a removeinvoice map for updateroute
        routeDetails.stops.forEach((stop: Stop) => {
          if (stop.stopType !== 'back_to_store') {
            stop.invoices.forEach((invoice) => {
              if (invoice.invoiceNumber === invoiceNumber) {
                setEditRemoveInvoices([...editRemoveInvoices, invoice])
              }
            })
          }
        })

        //Updating the route data with the removed invoices
        const removeInvoiceData = {
          ...routeDetails,
          stops: routeDetails?.stops.reduce((acc, stop) => {
            const updatedStop = {
              ...stop,
              invoices: stop.invoices?.reduce((invoicesAcc, invoice) => {
                if (invoice.invoiceNumber !== invoiceNumber) {
                  invoicesAcc.push(invoice)
                }
                return invoicesAcc
              }, [] as any[])
            }
            if (
              (updatedStop?.stopType !== 'back_to_store' && updatedStop.invoices?.length > 0) ||
              updatedStop?.stopType === 'back_to_store'
            ) {
              acc.push(updatedStop)
            }
            return acc
          }, [] as any[])
        }
        setEditedRouteData(removeInvoiceData)
      }
    }
  }

  const detectOrderChange = (original: any, reordered: any) => {
    const originalStops = original?.map((item: any) => item.stopId)
    const reorderStops = reordered?.map((item: any) => item.stopId)

    if (JSON.stringify(originalStops) !== JSON.stringify(reorderStops)) {
      const changedOrder = reorderStops
      return changedOrder
    } else {
      return []
    }
  }

  const handleUpdateRoute = async () => {
    if (routeDetails && route) {
      const newStopsOrder = detectOrderChange(routeDetails?.stops, reorderList)
      if (
        editAddInvoices.length > 0 ||
        editRemoveInvoices.length > 0 ||
        newStopsOrder?.length > 0 ||
        customStopDetails?.length > 0 ||
        removeCustomStopId.length > 0
      ) {
        const addInvoicesMap: { [key: string]: string } = {}
        const removeInvoicesMap: { [key: string]: string } = {}
        const removeInvoicesStopIdMap: { [key: string]: string } = {}

        editAddInvoiceData.forEach((invoice: InvoicesResponse) => {
          addInvoicesMap[invoice.invoiceNumber] = invoice.invoiceDateTime.toString()
        })

        editRemoveInvoices.forEach((invoice: Invoice) => {
          let stopId = null
          /* eslint-disable */
          route.stops.map((stop) => {
            if (stop.stopType === "delivery" && stop.invoices.length)
              if (stop.invoices.includes(invoice)) {
                stopId = stop.stopId
              }
          })

          removeInvoicesMap[invoice.invoiceNumber] = invoice.invoiceDateTime.toString()
          //@ts-ignore
          removeInvoicesStopIdMap[invoice.invoiceNumber] = stopId.toString()
        })

        try {
          await DeliveryRouteService.dispatchUpdateRoute({
            storeNumber: routeDetails.storeNumber,
            routeId: routeDetails.routeId,
            addInvoicesMap: addInvoicesMap,
            removeInvoicesMap: removeInvoicesMap,
            reorderStopsList: newStopsOrder,
            removeInvoicesStopIdMap: removeInvoicesStopIdMap,
            removeCustomStopId: removeCustomStopId,
            addCustomStopsList: customStopDetails,
          })
          successModal()
          setEditAddInvoiceData([])
          setEditRemoveInvoices([])
          setRemoveCustomStopId([])
          setCustomStopDetails([])
          setIsEditModal(false)
        } catch (e) {
          errorModal()
          return Promise.reject(e)
        }
      }

      if (routeDetails.deliveryDriver !== selectedDriverId) {
        try {
          const driver = selectedDriverId === 'None' ? null : selectedDriverId
          await DeliveryRouteService.assignRoute({
            routeId: routeDetails.routeId,
            deliveryDriver: driver
          })
          successModal()
        } catch (e) {
          errorModal()
          return Promise.reject(e)
        }
      }
      setOpenEditRoute(false)
      setIsEditModal(false)
    }
  }

  const isCustomStop = (invoiceType: string, type: string) => invoiceType === 'customStop' || type === 'custom'

  const handleRemoveStop = async (stopId: number) => {
    if (routeDetails?.stops?.length === 2 && routeDetails?.stops[0].stopType === 'custom') {
      setOpenCancelRoute(true)
      setOpenEditRoute(false)
    }
    else {
      const filteredStops = {
        ...routeDetails,
        stops: routeDetails?.stops.filter((obj: any) => obj.stopId !== stopId)
      }
      setRemoveCustomStopId([...removeCustomStopId, stopId])
      setEditedRouteData(filteredStops)
    }
  }

  const renderInvoice = (invoiceData: any, invoiceType: string, isDraggable: any, isDragging?: any, delivered?: boolean) => {
    return invoiceData?.map((invoice: any) => {
      return (
        <Accordion
          style={{
            boxShadow: 'none',
            background: isDragging ? Colors.napaGreyDisabled : Colors.white
          }}>
          <AccordionSummary
            expandIcon={<GridArrowDownwardIcon style={{ cursor: 'pointer' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={editRouteStyles.accordionSummary}>
            <Box style={editRouteStyles.renderInvoiceBox}>
              <NameContainer sx={editRouteStyles.invoiceDeliveryAddress}>
                {isDraggable && <CompressOutlinedIcon fontSize="small" />}{' '} {isCustomStop(invoiceType, invoice.stopType) ? invoice.customStopName ?? invoice.stopName : invoice.deliveryAddress?.name}
              </NameContainer>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '8px' }}>
            <Box style={editRouteStyles.renderAddressBox}>
              <AddressContainer sx={editRouteStyles.invoiceAddressLineType}>
                {isCustomStop(invoiceType, invoice.stopType) ? invoice.addressLine1 : invoice?.deliveryAddress?.addressLine1}
                {!!invoice?.referencePONumber && invoice?.referencePONumber != "" && <Typography style={editRouteStyles.invoiceAddressLineType}>PO: {invoice?.referencePONumber}</Typography>}
                {delivered === true ?
                  (
                    <Typography style={{ fontSize: '12px', color: 'green' }}>Delivered at: {convertEpochToTimezoneFormat(invoice?.deliveryTimestamp)}</Typography>
                  ) : null}
              </AddressContainer>
              {invoiceType !== 'addInvoice' && !delivered && !isCustomStop(invoiceType, invoice.stopType) ? (
                <Box sx={editRouteStyles.removeButtonBox}>
                  <Button
                    sx={editRouteStyles.removeInvoiceType}
                    onClick={() => handleRemoveInvoice(invoice.invoiceNumber)}>
                    {(language as any)[currentLanguage].removeInvoice}
                  </Button>
                </Box>
              ) : null}
              {isCustomStop(invoiceType, invoice.stopType) ? (
                <Box sx={editRouteStyles.removeButtonBox}>
                  <Button
                    sx={editRouteStyles.removeInvoiceType}
                    onClick={() => handleRemoveStop(invoice.stopId)}>
                    Remove Stop
                  </Button>
                </Box>
              ) : null}
            </Box>
            {!isCustomStop(invoiceType, invoice.stopType) && renderInvoiceAndPO(invoice)}
            {!isCustomStop(invoiceType, invoice.stopType) && <ItemHeader />}
            {!isCustomStop(invoiceType, invoice.stopType) && invoice.parts?.map((part: any) => {
              return (
                <>
                  <Item part={part} />
                  {invoice.invoiceNotes && invoice.invoiceNotes[0].actualNote && (
                    <Notes notes={invoice.invoiceNotes[0].actualNote} />
                  )}
                  <Divider sx={{ marginRight: 1 }} />
                </>
              )
            })}
            {isCustomStop(invoiceType, invoice.stopType) && <Notes notes={invoice.stopNotes} />}
            {isCompanyOwned && !isApac && !!invoice?.customerAddressId &&
              <Box sx={editRouteStyles.locationInfo}>

                <Box sx={editRouteStyles.locationSection}>
                  <Box sx={editRouteStyles.locationSectionHeader}>
                    <PinIcon />
                    Delivery Location
                  </Box>
                  <Box sx={editRouteStyles.locationSectionText}>
                    {!!invoice?.locationName && invoice?.locationName != "" ?
                      invoice?.locationName :
                      invoice?.deliveryAddress.addressLine1
                    }
                  </Box>
                </Box>

                {!!invoice?.customerDropoffName && invoice?.customerDropoffName != "" &&
                  <Box sx={editRouteStyles.locationSection}>
                    <Box sx={editRouteStyles.locationSectionHeader}>
                      <RedIcon style={editRouteStyles.redPin} />
                      Drop-off Name
                    </Box>
                    <Box sx={editRouteStyles.locationSectionText}>
                      {invoice?.customerDropoffName}
                    </Box>
                  </Box>
                }

                {!!invoice?.customerDeliveryNotes && invoice?.customerDeliveryNotes != "" &&
                  <Box sx={editRouteStyles.locationSection}>
                    <Box sx={editRouteStyles.locationSectionHeader}>
                      <NotesIcon />
                      Delivery Notes
                    </Box>
                    <Box sx={editRouteStyles.locationSectionText}>
                      {invoice?.customerDeliveryNotes}
                    </Box>
                  </Box>
                }

              </Box>
            }
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return

    const items = Array.from(reorderList ?? [])
    const [newOrder] = items?.splice(source.index, 1)
    items.splice(destination.index, 0, newOrder)
    setReorderList(items)
  }

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? Colors.napaGreyDisabled : Colors.white,
    cursor: 'row-resize',
    ...draggableStyle,
    ...editRouteStyles.renderInvoice
  })

  const checkIfDraggable = (list: any) => {
    return list.filter((stop: any) => stop.stopType !== 'back_to_store')?.length > 1
  }

  const renderInvoicesWithoutBackToStore = (invoiceType: string) => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <StrictModeDroppable droppableId="todo">
          {(provided) => (
            <div className="todo" {...provided.droppableProps} ref={provided.innerRef}>
              {reorderList?.map((stop: any, index: any) => {
                if (stop.delivered) {
                  return (
                    <div
                      key={stop.stopId?.toString()}
                      ref={provided.innerRef}
                      style={getItemStyle(false, null)}
                      data-testid={`stop-${stop.stopId.toString()}`}
                    >
                      {renderInvoice(stop.stopType === 'custom' ? [stop] : stop.invoices, invoiceType, false, false, true)}
                    </div>
                  )
                } else if (stop.stopType !== 'back_to_store' && !stop.delivered) {
                  return (
                    <Draggable key={stop.stopId?.toString()} draggableId={stop.stopId?.toString()} index={index}
                      isDragDisabled={reorderList?.length === 2}>
                      {(provided, snapshot) => (
                        <div
                          key={stop.stopId?.toString()}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          data-testid={`stop-${stop.stopId.toString()}`}
                        >
                          {renderInvoice(stop.stopType === 'custom' ? [stop] : stop.invoices, invoiceType, checkIfDraggable(reorderList), snapshot.isDragging)}
                        </div>
                      )}
                    </Draggable>
                  )
                }

              })}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    )
  }

  return (
    <Dialog open={openEditRoute} fullWidth={true} onClose={handleClose}>
      {routeDetails?.externalDelivery || createQuoteResponse ? (
        <ExternalDelivery
          handleBackArrow={handleBackArrow}
          handleClose={handleClose}
          setOpenCancelRoute={setOpenCancelRoute}
          routeDetails={routeDetails}
          createQuoteResponse={createQuoteResponse}
          ItemHeader={ItemHeader}
          Item={Item}
        />
      ) : (
        <>
          <DialogTitle sx={editRouteStyles.dialogTitle}>
            <IconButton onClick={handleClose}>
              <Clear fontSize="medium" />
            </IconButton>
          </DialogTitle>
          <Typography sx={editRouteStyles.routeReviewTypography}>
            {(language as any)[currentLanguage].routeNumber}
            {routeDetails?.routeId}
          </Typography>
          <CustomizedTitle style={{ padding: '16px 10px' }}>
            <Box>
              <DialogContentText sx={editRouteStyles.totalStops}>
                {(language as any)[currentLanguage].totalStops}{' '}
                {routeDetails && routeDetails.stops?.length + editAddInvoiceData.length}
              </DialogContentText>
            </Box>
            {((isCustomStopEnabled && isNonApac) || (isCustomStopEnabledApac && isApac)) ?
              <>
                <Button
                  variant='primary'
                  onClick={handleClick}
                  sx={editRouteStyles.invoiceMenu}
                >
                  {language[currentLanguage].addStop}
                  <KeyboardArrowDownSharpIcon sx={editRouteStyles.downIcon} />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenu}
                  style={{ marginTop: '5px' }}
                >
                  <MenuItem onClick={handleAddInvoice} sx={editRouteStyles.invoiceText} style={{ paddingTop: '0' }}>
                    <AddOutlinedIcon fontSize='small' sx={editRouteStyles.marginR} />
                    {language[currentLanguage].addInvoice}
                  </MenuItem>
                  <MenuItem onClick={handleCustomStop} sx={editRouteStyles.invoiceText} style={{ paddingBottom: '0' }}>
                    <EditOutlinedIcon fontSize='small' sx={editRouteStyles.marginR} />
                    {language[currentLanguage].customStop}
                  </MenuItem>
                </Menu>
              </> :
              <Box style={{ marginTop: '-35px' }}>
                <Button
                  onClick={() => {
                    setOpenAddInvoices(true)
                  }}
                  sx={editRouteStyles.editButton}>
                  {(language as any)[currentLanguage].addInvoices}
                </Button>
              </Box>}
          </CustomizedTitle>
          <Box style={{ marginTop: '-15px' }}>
            <Button
              onClick={() => {
                setShowMap(!showMap)
              }}
              style={{ top: '-18px', paddingLeft: '12px' }}
              sx={editRouteStyles.editButton}>
              {showMap ? 'Hide Map' : 'Show Map'}
            </Button>
          </Box>
          {showMap && <Grid item xs={12} sx={editRouteStyles.RouteMapPreview}>
            <CreateRoutePreviewMap
              routeDetails={routeDetails}
              getMapRouteCoordinates={getMapRouteCoordinates}
            />
          </Grid>}
          {showWarning && (
            <Box style={{ marginTop: '-23px', marginBottom: '5px' }}>
              <Alert
                sx={{ backgroundColor: Colors.warningBgColor, color: Colors.warningTextColor }}
                severity="warning"
                icon={<Warning sx={{ color: Colors.warningIconColor }} />}
                style={{ fontSize: '12px', margin: '0px 10px' }}>
                {(language as any)[currentLanguage].editRouteWarningMessage}
              </Alert>
            </Box>
          )}
          <CustomisedDialogContent
            style={{ marginTop: showWarning ? '0px' : '-23px', overflowY: 'unset' }}>
            {editAddInvoiceData.length > 0 ? (
              <div style={editRouteStyles.renderInvoice}>
                {renderInvoice(editAddInvoiceData, 'addInvoice', false)}
              </div>
            ) : null}
            {customStopDetails?.length > 0 ? (
              <div style={editRouteStyles.renderInvoice}>
                {renderInvoice(customStopDetails, 'customStop', false)}
              </div>
            ) : null}
            {renderInvoicesWithoutBackToStore('edit')}
          </CustomisedDialogContent>
          {checkBackToStoreStop(routeDetails as DeliveryRoute) && (
            <CustomisedDialogContent
              sx={{ marginTop: '10px', overflowY: 'visible', paddingBottom: '0px' }}>
              <Box sx={editRouteStyles.returnStore}>
                <NameContainer sx={editRouteStyles.invoiceDeliveryAddress}>
                  {(language as any)[currentLanguage].returnToStore}
                </NameContainer>
                <DialogContentText sx={editRouteStyles.invoiceAddressLineType}>
                  {storeAddress?.addressLine1}, {storeAddress?.addressLine2 ?? ''}{' '}
                  {storeAddress?.city}, {storeAddress?.state} {storeAddress?.zipCode}
                </DialogContentText>
                {routeDetails?.routeCreationTimestamp && (
                  <DialogContentText sx={editRouteStyles.invoiceAddressLineType}>
                    ETA{' '}
                    {convertEpochToTimezoneFormat(routeDetails.routeCreationTimestamp, routeETA)}
                  </DialogContentText>
                )}
              </Box>
            </CustomisedDialogContent>
          )}
          <Box sx={editRouteStyles.stickyBottom}>
            {selectDriver()}
            {isDoordashEnabled && (
              <DialogActions sx={editRouteStyles.dialogActions} style={{ overflowY: 'visible' }}>
                <>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      handleCreateQuote()
                    }}
                    disabled={
                      disableThirdParty ||
                      isApac ||
                      // Doordash is enabled for single stop Routes. Length to stops is 2 including the 'return to store' stop.
                      !(route?.stops?.length === 2 && route.routeStatus === 'created' && route?.stops[0].stopType !== 'custom')
                    }
                    sx={editRouteStyles.launchButton}>
                    {(language as any)[currentLanguage].launch}
                    <ArrowForward>
                      <Clear fontSize="medium" />
                    </ArrowForward>
                  </Button>
                </>
              </DialogActions>
            )}

            <DialogActions sx={editRouteStyles.dialogActions} style={{ overflowY: 'visible' }}>
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setOpenCancelRoute(true)
                    setOpenEditRoute(false)
                  }}
                  fullWidth
                >
                  {(language as any)[currentLanguage].cancelRoute}
                </Button>
                <Button
                  variant="primary"
                  onClick={async () => {
                    await handleUpdateRoute()
                    refetchRoutes()
                  }}
                  fullWidth
                >
                  {(language as any)[currentLanguage].updateRoute}
                </Button>
              </>
            </DialogActions>
          </Box>
        </>
      )}
    </Dialog>
  )
}

const CustomizedTitle = styled(DialogTitle)`
  color: blue;
  display: flex;
  justify-content: space-between;
`

const CustomisedDialogContent = styled(DialogContent)`
  color: green;
  border-width: 1px;
  border-color: red;
  border-width-color: blue;
`

const NameContainer = styled(DialogContentText)`
  font-size: 14px;
  color: blue;
`

const AddressContainer = styled(DialogContentText)`
  font-size: 14px;
`

const AllItemText = styled(DialogContentText)`
  font-size: 16px;
  margin-top: 12px;
`

export default EditRoute
