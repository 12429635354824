import { useContext } from 'react'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import { StoreProvider } from '../../utilities/contexts/StoreContext'
import NavigationBar from '../NavigationBar/NavigationBar'
import { Route, Routes } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import TermsAndConditions from '../../screens/TermsAndConditions/TermsAndConditions'
import SignIn from '../../screens/SignIn/SignIn'
import ForgotPassword from '../../screens/ForgotPassword/ForgotPassword'
import ResetPassword from '../../screens/ResetPassword/ResetPassword'
import CompleteRegistration from '../../screens/NewUser/CompleteRegistration'
import NavBarMenu from './NavBarMenu'
import { BusinessUnitConfigProvider } from '../../utilities/contexts/businessUnitConfigContext'
import Landing from '../../screens/Landing/Landing'

export const Navigator = () => {
  const { currentUser, termsAccepted, isInitializingCurrentUser, isInitializingTerms } =
    useContext(AuthContext)

  if (isInitializingCurrentUser || isInitializingTerms)
    return (
      <div className="Loading-container">
        <CircularProgress />
      </div>
    )

  if (currentUser) {
    if (termsAccepted) {
      return (
        <StoreProvider>
          <BusinessUnitConfigProvider>
            <NavigationBar />
            <NavBarMenu />
          </BusinessUnitConfigProvider>
        </StoreProvider>
      )
    } else {
      return <TermsAndConditions />
    }
  } else if (currentUser === undefined) {
    return <div>Loading</div>
  } else {
    return (
      <Routes>
        <Route index element={<Landing />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/Registration" element={<CompleteRegistration />} />
        <Route path="*" element={<Landing />} />
      </Routes>
    )
  }
}

export default Navigator
