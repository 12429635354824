import Colors from "../../../assets/Colors";

export const customerDetailsStyles = {
    typoSmall: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19.5px',
        textAlign: 'center',
        color: Colors.napaGrey2
    },
    typoHeader3 : {
        //styleName: H3;
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaBlue
    },
    typoHeader5: {
        //styleName: H5;
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.32px',
        textAlign: 'left',
        color: Colors.napaGrey2
    },
    paragraphBold2: {
        //styleName: Paragraph 2 Bold;
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0.36px',
        textAlign: 'left',
        color: Colors.napaGrey2,
        height: '30px'
    },
    paragraph2: {
        //styleName: Paragraph 2;
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaGrey2
    },
    paragraph2Link : {
        //styleName: Paragraph 2;
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaBlueLink,
        textTransform: 'none'
    },
    paragraph2LinkDisabled : {
        //styleName: Paragraph 2;
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.48px',
        textAlign: 'left',
        color: Colors.napaGreyDisabled,
        textTransform: 'none'
    },
    accountDetailsLayout: {
        margin: '0px',
        border: '1px solid #D6D6D6',
        borderRadius: '5px',
        padding: '20px'
    },
    serviceLevel: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center'

    },
    small : {
        //styleName: Small;
        fontSize: '12px',
        fontWeight: '400',
        lineeHight: '15px',
        letterSpacing: '0.42px',
        textAlign: 'center',
        color: Colors.napaBlueLink
    },
    noPinMap:{
      flex: 1,
      width: '100%',
      height: '100%'
    },
    buttonContainer:{
        display: "flex"
    },
    textButton: {
        padding: '5px',
        fontSize: '12px',
        fontWeight: '400',
        lineeHight: '15px',
        letterSpacing: '0.42px',
        textAlign: 'center',
        color: Colors.napaBlueLink
    },
    addressLabel: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0.36px',
        textAlign: 'left',
        color: Colors.napaGrey2,
        flexGrow: 1
    },
    defaultAddress: {background: '#E5E7F3', padding: '5px 16px', borderRadius: '3px', fontSize: '12px', fontWeight: '600', gap: 1, display: 'flex', alignItems: 'center'},
    slaSelect: {height : '40px'},
    slaError: {color: 'red', display: 'flex'}
}