import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import { Address } from '../types/DeliveryRouteTypes'
import { getStoreDetails, StoreDetails } from '../services/StoreService/StoreService'

export const StoreContext = createContext<StoreContextType>({
  currentStore: '',
  changeStore: () => {},
  storeAddress: {} as Address,
  isDoordashEnabled: false,
  isCompanyOwned: false,
  autoDispatchEnabled: false
})

export type StoreContextType = {
  currentStore: string
  changeStore: (storeNumber: string) => void
  storeAddress: Address,
  isDoordashEnabled: boolean,
  isCompanyOwned: boolean,
  autoDispatchEnabled: boolean
}

export const StoreProvider = ({ children }: any): ReactElement => {
  const [currentStore, setCurrentStore] = useState<string>('')
  const [storeAddress, setStoreAddress] = useState<Address>({} as Address)
  const [isCompanyOwned, setIsCompanyOwned ] = useState<boolean>(false)
  const [isDoordashEnabled, setIsDoordashEnabled] = useState<boolean>(false)
  const [autoDispatchEnabled, setAutoDispatch] = useState<boolean>(false)
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    const changeStoreSignIn = async (storeNumber: string) => {
      const storeDetails = await getStoreDetails(storeNumber)
      setStoreAddress(storeDetailsToAddress(storeDetails))
      setIsDoordashEnabled(storeDetails.doordashEnabledFlag)
      setAutoDispatch(storeDetails.autoDispatch)
      setIsCompanyOwned(storeDetails.companyOwnedFlag)
    }

    if (currentUser) {
      setCurrentStore(currentUser.defaultStoreNumber)
      changeStoreSignIn(currentUser.defaultStoreNumber)
    }
  }, [currentUser])

  const changeStore = async (storeNumber: string) => {
    setCurrentStore(storeNumber)// import { Address } from '../../types/DeliveryRouteTypes'
    const storeDetails = await getStoreDetails(storeNumber)
    setStoreAddress(storeDetailsToAddress(storeDetails))
    setIsDoordashEnabled(storeDetails.doordashEnabledFlag)
    setAutoDispatch(storeDetails.autoDispatch)
    setIsCompanyOwned(storeDetails.companyOwnedFlag)
  }

  const storeDetailsToAddress = (storeDetails: StoreDetails): Address => {
    return {
      addressLine1: storeDetails.addressLine1,
      addressLine2: storeDetails.addressLine2,
      city: storeDetails.city,
      state: storeDetails.state,
      zipCode: storeDetails.zipCode,
      longitude: storeDetails.longitude,
      latitude: storeDetails.latitude
    }
  }

  return (
    <StoreContext.Provider
      value={{
        currentStore,
        changeStore,
        storeAddress,
        isCompanyOwned,
        isDoordashEnabled,
        autoDispatchEnabled
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
