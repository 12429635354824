import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import { LocationUpdateStyles } from "./LocationUpdateStyles"
import { InvoicesResponse } from "../../../../utilities/types/DeliveryRouteTypes"
import DeliveryLocationSelect from "../../../../components/DeliveryLocationSelect/DeliveryLocationSelect"
import { ConfirmAddressPayload, CustomerResponse, CustomerService } from "../../../../utilities/services/CustomerService/CustomerService"
import { UseQueryResult } from "react-query"
import { useContext, useEffect } from "react"
import { StoreContext } from "../../../../utilities/contexts/StoreContext"
import { Clear } from "@mui/icons-material"
import language from "../../../../language/language"
import { LanguageContext } from "../../../../utilities/contexts/LanguageContext"
import { SnackbarContext } from "../../../../utilities/contexts/SnackbarContext"



type LocationUpdateProp = {
    openLocationDialog: boolean
    setLocationInvoiceDetails: (value: any) => void
    setOpenLocationDialog: (open: boolean) => void
    invoiceDetails: InvoicesResponse[]
    showBuildRouteButton: boolean
    setDropdownARNumbers: (value: string[]) => void
    lookupResults: UseQueryResult<CustomerResponse, any>[]
    handleCreateRouteClick: (value: boolean) => void
    refetchInvoices: () => {}
}

const LocationUpdate = ({
    openLocationDialog,
    setOpenLocationDialog,
    invoiceDetails,
    showBuildRouteButton,
    setDropdownARNumbers,
    lookupResults,
    handleCreateRouteClick,
    refetchInvoices
}: LocationUpdateProp) => {

    const handleClose = () => {
        setDropdownARNumbers([])
        setOpenLocationDialog(false)
    }

    const { currentStore } = useContext(StoreContext)
    const { currentLanguage } = useContext(LanguageContext)
    const { addSnack } = useContext(SnackbarContext)

    useEffect(() => {
        const arNumbers = invoiceDetails.map((invoice) => invoice.customerNumber)
        setDropdownARNumbers(arNumbers)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDetails])

    const updateConfirmAddress = async (payloadArray: ConfirmAddressPayload[]) => {
        await CustomerService.updateConfirmAddress(payloadArray)
        setOpenLocationDialog(false)
        refetchInvoices()
        addSnack({
            severity: 'success',
            message: `Address update success for Invoice #${invoiceDetails[0].invoiceNumber}`,
            action: null,
            duration: 3000
        })
    }

    return <Dialog
        fullWidth={true}
        open={openLocationDialog} onClose={handleClose}>
        <DialogTitle>
            {invoiceDetails
                && invoiceDetails.length > 1 &&
                <Grid>
                    <Typography sx={LocationUpdateStyles.headerText}>Select Location</Typography>
                    <Typography sx={LocationUpdateStyles.paragraph1}>
                        The invoice you selected has a customer with multiple locations. Please select the location you want to deliver to or add a new one.
                    </Typography>
                </Grid>}
        </DialogTitle>
        <DialogContent>
            <Grid item xs={1} textAlign={'right'}>
                <IconButton onClick={handleClose} sx={LocationUpdateStyles.closeButton}>
                    <Clear fontSize="medium" />
                </IconButton>
            </Grid>
            {invoiceDetails.map((eachInvoice, index) =>
                <Grid key={index}>
                    <Box>
                        <Typography sx={LocationUpdateStyles.invoiceText}>Invoice #{eachInvoice?.invoiceNumber}</Typography>
                        <Typography sx={LocationUpdateStyles.companyNameText}>{eachInvoice?.deliveryAddress.name}</Typography>
                        {!!eachInvoice?.referencePONumber && <Typography sx={LocationUpdateStyles.smallText}>PO: #{eachInvoice?.referencePONumber}</Typography>}
                    </Box>
                    <Box>

                        <DeliveryLocationSelect
                            invoiceDetails={eachInvoice}
                            lookupResponse={!!lookupResults && lookupResults.length > index ? lookupResults[index] : null}
                            refetchInvoices={refetchInvoices}
                        />

                    </Box>
                    <Box>

                    </Box>

                </Grid>
            )}
        </DialogContent>

        <DialogActions>

            {showBuildRouteButton === false && invoiceDetails && invoiceDetails.length === 1 && (
                <Button variant='contained' fullWidth disabled={invoiceDetails[0].requiresAddressUpdate === false} onClick={() => {
                    let addrsPayload: ConfirmAddressPayload = {} as ConfirmAddressPayload
                    addrsPayload.storeNumber = currentStore
                    addrsPayload.invoiceNumber = invoiceDetails[0].invoiceNumber.toString() || ''
                    addrsPayload.invoiceDateTime = invoiceDetails[0].invoiceDateTime.toString() || 0 + ''
                    let payloadArray: ConfirmAddressPayload[] = []
                    payloadArray.push(addrsPayload)
                    updateConfirmAddress(payloadArray)
                }}>{invoiceDetails[0].requiresAddressUpdate === true && (language as any)[currentLanguage].confirm}
                    {invoiceDetails[0].requiresAddressUpdate === false && 'Confirmed'}
                </Button>)
            }

            {showBuildRouteButton === true &&
                (<Button variant='contained' fullWidth onClick={() => {
                    setOpenLocationDialog(false)
                    handleCreateRouteClick(false)
                }}>{(language as any)[currentLanguage].buildRouteText}</Button>)}

        </DialogActions>
    </Dialog>
}

export default LocationUpdate;
