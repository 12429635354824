import axiosInstance from '../../helpers/AxiosHelper'

export async function getStoreDetails(storeNumber: string): Promise<StoreDetails> {
  try {
    const response = await axiosInstance.get<StoreDetails>(`/napaStores/store/${storeNumber}`)
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return {
      storeNumber: storeNumber,
      doordashEnabledFlag: false
    } as StoreDetails
  }
}

export async function getStores(username: string): Promise<string[]> {
  try {
    const response = await axiosInstance.get<string[]>(`/store/username/${username}`)
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return []
  }
}

export type StoreDetails = {
  storeNumber: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipCode: string
  latitude: number
  longitude: number
  doordashEnabledFlag: boolean
  autoDispatch: boolean
  companyOwnedFlag: boolean
}
