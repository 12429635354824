import Cookies from 'js-cookie'

export const getCOSFromStorage = (name: string): boolean => {
  return Cookies.get(name) === 'true'
}

export const setCOStoStorage = (name: string, value: string) => {
  Cookies.set(name, value, { sameSite: 'None', secure: true, expires: 1, domain: '.genpt.com' })
}

export const removeCOSFromStorage = (name: string) => {
  Cookies.remove(name,{sameSite: 'None', secure: true, expires: 1, domain: '.genpt.com'})
}

export const calculateExpireTime = (time: string): number => {
  return (Number(time) - 300) * 1000
}

export const clearCookieAndRedirectHome = () => {
  removeCOSFromStorage('authenticated')
  removeCOSFromStorage('isCOS')
  window.location.href = process.env.REACT_APP_USAG_HOME_URL ?? '/'
}
