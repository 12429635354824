import axios, { AxiosError } from 'axios'
import { AuthHelper } from './AuthHelper'
import { clearCookieAndRedirectHome, getCOSFromStorage } from './CosHelper'

const isCOS = getCOSFromStorage('isCOS')

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DELIVERYAPI_URL,
  ...(isCOS && { withCredentials: true })
})

// Add a request interceptor to handle token insertion
axiosInstance.interceptors.request.use(
  async (config) => {
    if (isCOS) {
      config.headers['Cos'] = 'true'
    } else {
      // Wait for the token to be fetched asynchronously
      const token = (await AuthHelper.getUserAuthTokens()).accessToken

      // Set the Authorization header before sending the request
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // If unauthorized return to login screen
    if (error.response?.status === 401) {
      if (isCOS) {
        clearCookieAndRedirectHome()
      }

      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
