import { Button, IconButton, Menu, MenuItem, Modal, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import language from '../../../../language/language'
import { ThirdPartyTransferModalStyles } from './ThirdPartyTransferModalStylesType'
import { Address, TransferStop } from '../../../../utilities/types/DeliveryRouteTypes'
import Colors from '../../../../assets/Colors'
import { KeyboardArrowDownSharp, StoreOutlined } from '@mui/icons-material'
import { invoiceListStyles } from '../InvoiceList/invoiceListStyles'
import CloseIcon from '@mui/icons-material/Close'
import TransferCustomerSearch from './TransferCustomerSearch'

type ThirdPartyTransferModalType = {
  isOpen: boolean
  closeModalCallback: () => void
}

const ThirdPartyTransferModal = ({ isOpen, closeModalCallback }: ThirdPartyTransferModalType) => {
  const { storeAddress } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)

  const [openStoreSearch, setOpenStoreSearch] = useState<boolean>(false)
  const [openCustomerSearch, setOpenCustomerSearch] = useState<boolean>(false)
  // const [sourceAddress, setSourceAddress] = useState<TransferStop>(null) 
  const [destinationStop, setDestinationStop] = useState<TransferStop | null>(null)

  const closeModalHandler = () => {
    setOpenCustomerSearch(false)
    setOpenStoreSearch(false)
    setDestinationStop(null)
    closeModalCallback()
  }

  const buildTransferHandler = () => {}

  const closeCustomerSearchCallback = () => {
    setOpenCustomerSearch(false)
  }

  return (
    <Modal open={isOpen} onClose={closeModalHandler} sx={ThirdPartyTransferModalStyles.modal}>
      <div style={ThirdPartyTransferModalStyles.rootContainer}>
        {/* {openStoreSearch && <StoreSearch isDestination={isDestinationSearch} setSourceStop={setSourceStop} setDestinationStop={setDestinationStop}/>} */}
        {openCustomerSearch && <TransferCustomerSearch setDestinationStop={setDestinationStop} closeSearchCallback={closeCustomerSearchCallback}/>}
        {!openStoreSearch && !openCustomerSearch && <>
          <div style={ThirdPartyTransferModalStyles.rootTitleContainer}>
            <Typography fontSize={'22px'} color={Colors.napaBlue}>
              {language[currentLanguage].thirdPartyTransfer}
            </Typography>
            <IconButton aria-label="close" onClick={closeModalHandler}>
              <CloseIcon sx={invoiceListStyles.closeIconImg} />
            </IconButton>
          </div>
          <SourceCard storeAddress={storeAddress} />
          <DestinationCard setOpenStoreSearch={setOpenStoreSearch} setOpenCustomerSearch={setOpenCustomerSearch} destinationStop={destinationStop}/>
          <div style={ThirdPartyTransferModalStyles.buttonContainer}>
            <Button
              variant="secondary"
              sx={ThirdPartyTransferModalStyles.buttonStyle}
              onClick={closeModalCallback}>
              {language[currentLanguage].cancel}
            </Button>
            <Button
              variant="primary"
              onClick={buildTransferHandler}
              sx={ThirdPartyTransferModalStyles.buttonStyle}
              disabled={true}>
              {language[currentLanguage].buildTransfer}
            </Button>
          </div>
        </>}
      </div>
    </Modal>
  )
}

const SourceCard = ({ storeAddress }: { storeAddress: Address }) => {
  const editSourceHandler = () => {
    return
  }

  const { currentLanguage } = useContext(LanguageContext)
  return (
    <div style={ThirdPartyTransferModalStyles.cardRootContainer}>
      <div style={ThirdPartyTransferModalStyles.cardTitleContainer}>
        <div style={ThirdPartyTransferModalStyles.cardTitleSubGroupContainer}>
          <StoreOutlined />
          <Typography>{language[currentLanguage].source}</Typography>
        </div>
        <Button onClick={editSourceHandler}>{language[currentLanguage].edit}</Button>
      </div>
      <div style={ThirdPartyTransferModalStyles.cardBodyContainer}>
        {/*TODO: Gotta fix this to be the businessName once we populate that somehow in the response object, either need to get the store location through our API or save the business name*/}
        <Typography fontWeight="bold">{storeAddress.addressLine1}</Typography>
        <div style={ThirdPartyTransferModalStyles.offsetText}>
          <Typography>{storeAddress.addressLine1}</Typography>
          {storeAddress.addressLine2 ? <Typography>{storeAddress.addressLine2}</Typography> : null}
          <Typography>{`${storeAddress.city}, ${storeAddress.state}, ${storeAddress.zipCode}`}</Typography>
        </div>
      </div>
    </div>
  )
}

const DestinationCard = ({setOpenStoreSearch, setOpenCustomerSearch, destinationStop}: {setOpenStoreSearch: (value: boolean) => void, setOpenCustomerSearch: (value: boolean) => void, destinationStop: TransferStop | null}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const editDestinationHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const { currentLanguage } = useContext(LanguageContext)
  return (
    <div style={ThirdPartyTransferModalStyles.cardRootContainer}>
      <div style={ThirdPartyTransferModalStyles.cardTitleContainer}>
        <div style={ThirdPartyTransferModalStyles.cardTitleSubGroupContainer}>
          <StoreOutlined />
          <Typography>{language[currentLanguage].destination}</Typography>
        </div>
        <Button
          onClick={editDestinationHandler}
          variant="primary"
          sx={ThirdPartyTransferModalStyles.selectButtonStyle}>
          {language[currentLanguage].select}
          <KeyboardArrowDownSharp />
        </Button>
      </div>
      <div style={ThirdPartyTransferModalStyles.cardBodyContainer}>
        {!!destinationStop && 
          <>
            <Typography fontWeight="bold">{destinationStop.businessName}</Typography>
            <div style={ThirdPartyTransferModalStyles.offsetText}>
              <Typography>{destinationStop.addressLine1}</Typography>
              {/* {destinationStop.addressLine2 ? <Typography>{destinationStop.addressLine2}</Typography> : null} */}
              <Typography>{`${destinationStop.city}, ${destinationStop.state}, ${destinationStop.zipCode}`}</Typography>
            </div>
          </>
        }
      </div>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => {setOpenStoreSearch(true)}}>{language[currentLanguage].storeString}</MenuItem>
        <MenuItem onClick={() => {setOpenCustomerSearch(true)}}>{language[currentLanguage].customer}</MenuItem>
      </Menu>
    </div>
  )
}

export default ThirdPartyTransferModal