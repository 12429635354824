
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { deliveryLocationSelectStyles } from './deliveryLocationSelectStyles'
import FmdGood from '@mui/icons-material/FmdGood'
import { DialogContentText, MenuItem } from '@mui/material'
import {
  AddressInfo, CustomerResponse, CustomerService, CustomerUpdateAddressPayload,
  DeliveryGeoCodes,
} from '../../utilities/services/CustomerService/CustomerService'
import { InvoicesResponse } from '../../utilities/types/DeliveryRouteTypes'
import { useContext, useState } from 'react'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { SnackbarContext } from '../../utilities/contexts/SnackbarContext'
import language from '../../language/language'
import { styled } from '@mui/material/styles'
import { ReactComponent as NotesIcon } from '../../assets/notes.svg'
import { UseQueryResult } from 'react-query'

interface DynamicSelectProps {
  invoiceDetails: InvoicesResponse
  lookupResponse: UseQueryResult<CustomerResponse, any> | null
  refetchInvoices: () => {}
}

const AddressContainer = styled(DialogContentText)`
  font-size: 14px;`

const DeliveryLocationSelect = ({
  invoiceDetails,
  lookupResponse,
  refetchInvoices
}: DynamicSelectProps) => {


  const { currentLanguage } = useContext(LanguageContext)
  const { currentStore } = useContext(StoreContext)
  const { addSnack } = useContext(SnackbarContext)

  const [currentAddress, setCurrentAddress] = useState<AddressInfo | undefined>(undefined);
  const customerDetails = lookupResponse?.data?.commercialCustomerListInfo[0]
  const addressList = customerDetails?.addressInfo

  const handleChange = (event: SelectChangeEvent) => {
    const addressId = event.target.value
    handleOnAddressChange(addressId)
  }

  const handleOnAddressChange = async (addressId: string) => {
    if (!!addressList) {
      const selectedAddress = addressList.filter((address: AddressInfo) => address.addressId === addressId)
      const filteredCustomerDetails = { ...customerDetails, addressInfo: selectedAddress }

      let addrsPayload: CustomerUpdateAddressPayload = {} as CustomerUpdateAddressPayload
      addrsPayload.storeNumber = currentStore
      addrsPayload.invoiceNumber = invoiceDetails?.invoiceNumber || ''
      addrsPayload.invoiceDateTime = invoiceDetails?.invoiceDateTime || 0
      addrsPayload.customerAddressDetails = filteredCustomerDetails
      const response = await CustomerService.updateCustomerAddressInfo(addrsPayload)
      if (response.status === 'success') {
        setCurrentAddress(selectedAddress.at(0));
        refetchInvoices()
      } else {
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorMessage,
          duration: 3000
        })
      }
    }
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <Box sx={deliveryLocationSelectStyles.selectHeader}>
        <h5 style={deliveryLocationSelectStyles.deliveryLocation}>
          <FmdGood sx={deliveryLocationSelectStyles.icon} fontSize="inherit" /> Delivery Location
        </h5>
        {/* NOT FOR PILOT !!addressList && addressList?.length > 0 &&
        <span style={deliveryLocationSelectStyles.addLocation}>
          {' '}
          <EditOutlined sx={deliveryLocationSelectStyles.icon} fontSize="inherit" /> Add Location
        </span> /* NEED TO ROUTE TO EDIT ADDRESS PAGE ON CLICK OF THIS BUTTON* */}
      </Box>
      <FormControl fullWidth>
        <Select id="dynamic-native-select" value={!!currentAddress ? currentAddress.addressId : invoiceDetails?.customerAddressId} onChange={handleChange}>
          <MenuItem value="" disabled>
            {lookupResponse?.isLoading ? "Loading..." : "Select An Address"}
          </MenuItem>
          {addressList?.map((address) => {
            const validDropoffSpot: DeliveryGeoCodes | undefined = !!address ? address.deliveryGeocodes?.find(dGeocode => { return dGeocode.isDefault === 'Y' && !!dGeocode.pinLabel }) : undefined
            return (
              <MenuItem key={address.addressId} value={address.addressId}>
                <div>
                  <span style={deliveryLocationSelectStyles.selectMenuItemLine1}>
                    {!!address.addressLabel && address.addressLabel !== '' ? address.addressLabel : address.addressline1}
                  </span>
                  {!!validDropoffSpot && <span style={deliveryLocationSelectStyles.selectMenuItemLine2}>
                    {validDropoffSpot.pinLabel}
                  </span>}
                </div>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Box>
        {!!currentAddress ?
          <AddressContainer style={deliveryLocationSelectStyles.addressComponentTwo}>
            {currentAddress?.addressline1},{' '}
            {currentAddress?.addressline2 ?? ''}{' '}
            {currentAddress?.city}, {currentAddress?.state}{' '}
            {currentAddress.zip}
          </AddressContainer>
          :
          <AddressContainer style={deliveryLocationSelectStyles.addressComponentTwo}>
            {invoiceDetails?.deliveryAddress?.addressLine1},{' '}
            {invoiceDetails?.deliveryAddress?.addressLine2 ?? ''}{' '}
            {invoiceDetails?.deliveryAddress?.city}, {invoiceDetails?.deliveryAddress?.state}{' '}
            {invoiceDetails?.deliveryAddress?.zipCode}
          </AddressContainer>
        }
      </Box>
      {((!!currentAddress ? !!currentAddress.note && currentAddress.note !== "" :
        !!invoiceDetails?.customerDeliveryNotes && invoiceDetails?.customerDeliveryNotes !== "") &&
        <Box style={deliveryLocationSelectStyles.addressSection}>
          <Box style={deliveryLocationSelectStyles.addressHeader}>
            <NotesIcon /> Delivery Notes
          </Box>
          <Box style={deliveryLocationSelectStyles.addressComponent}>
            {!!currentAddress ? currentAddress.note : invoiceDetails?.customerDeliveryNotes}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default DeliveryLocationSelect
