import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import customerManagementStyles from './customerManagementStyles';
import language from "../../language/language";
import { LanguageContext } from "../../utilities/contexts/LanguageContext";
import { useContext, useEffect, useState } from "react";
import CustomerDetailsList from "./CustomerDetailsList/CustomerDetailsList";
import { useCustomerDetailsByStore } from "../../queries/Customer/useCustomerDetailsByStore";
import emptyCustomerResponse, { CustomerAddressResponse, CustomerDetails, CustomerResponse, SlaUpdatePayload, transformAddressAPIResponse, AddressInfo } from "../../utilities/services/CustomerService/CustomerService";
import CustomerDetailsView from "./CustomerDetails/CustomerDetailsView";
import React from "react";

const CustomerManagement = () => {
    const { currentLanguage } = useContext(LanguageContext)
    const [arSearchValue, setARSearchValue] = useState('')
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)
    const [customerDetails, setCustomerDetails] = useState<CustomerResponse>();
    const {data: customerDetailsResp, refetch: searchCustomerDetails, error: errorCustomersQuery} = useCustomerDetailsByStore(arSearchValue, page, pageSize);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails | null>()
    
    useEffect(()=>{
        searchCustomerDetails()
    },[page, pageSize, searchCustomerDetails])

    useEffect(()=>{
        setCustomerDetails(customerDetailsResp)
    }, [customerDetailsResp])

    const changeSelectedCustomer = (customerDetails: CustomerDetails) => {
        const defaultAddressIndex : number = customerDetails?.addressInfo?.findIndex((address : AddressInfo) => address.isPreferredAddress === 'Y')

        if(defaultAddressIndex !== -1) {
            const defaultAddress : AddressInfo = customerDetails?.addressInfo?.splice(defaultAddressIndex, 1)[0]
            customerDetails?.addressInfo?.unshift(defaultAddress)
        }

        setSelectedCustomer(customerDetails)
    }

    return (
        <>
        {!!selectedCustomer ? 
            <CustomerDetailsView 
                customerDetails={selectedCustomer} 
                onBackPageClick={()=>{
                    selectedCustomer.addressInfo = selectedCustomer?.addressInfo.filter(info=>{
                        return !!info.addressId;
                    }) //remove unsaved address
                    setSelectedCustomer(null)
                }}
                onCustomerUpdate={(updatedAddress: CustomerAddressResponse | null, needsAddressRefetch: boolean, slaPayload: SlaUpdatePayload | null, customerId : string | null)=>{
                        if(!!!updatedAddress) {
                            if(!!slaPayload && !!customerId){
                                customerDetails?.commercialCustomerListInfo?.forEach(customer => {
                                    if(customer?.customerInfo?.customerId === customerId) {
                                        customer.customerInfo.priority = slaPayload.priority
                                    } 
                                })
                                if(!!customerDetails){
                                    setCustomerDetails({...customerDetails})
                                }
                            }
                        }
                        else if(!!needsAddressRefetch) {
                            searchCustomerDetails()
                        }
                        else {
                            customerDetails?.commercialCustomerListInfo.forEach((customer)=>{
                                if (customer.customerInfo.customerId === updatedAddress.customerId) {
                                    if(updatedAddress.isPreferredAddress === 'Y'){//is new default
                                        customer.addressInfo.forEach((address: AddressInfo) => {address.isPreferredAddress = 'N'})
                                    }
                                    if (!customer.addressInfo.find(address=>address.addressId === updatedAddress.addressId)) {
                                        const emptyAddIdIdx = customer.addressInfo.findIndex(address => !address.addressId)
                                        if (emptyAddIdIdx >= 0) {
                                            customer.addressInfo[emptyAddIdIdx] = transformAddressAPIResponse(updatedAddress)
                                        } else {
                                            customer.addressInfo.push(transformAddressAPIResponse(updatedAddress))
                                        }
                                        
                                    } else {
                                        customer.addressInfo = customer.addressInfo.map(address=>{
                                            if (address.addressId === updatedAddress.addressId) {
                                                return transformAddressAPIResponse(updatedAddress);
                                            }
                                            return address;
                                        })
                                    }
                                    changeSelectedCustomer(customer)
                                }
                            })
                            setCustomerDetails(customerDetails)
                        }
                    }}
            /> : <></>
        }
        <Grid container spacing={3} padding={2} display={selectedCustomer ? 'none' : ''}>
            <Grid item container xs={12} style={customerManagementStyles.gridContainer}>
                <Grid>
                    <Typography style={customerManagementStyles.header}>{(language as any)[currentLanguage].customerMgmt}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3}>
            <Box display="flex" flexDirection="column" gap={2} style={customerManagementStyles.searchContainer}>
                <Box display="flex" flexDirection="column" gap="8px">
                    <Typography style={customerManagementStyles.sectionHeader} align="left">
                        {(language as any)[currentLanguage].customerMgmtSearchHeader}
                    </Typography>
                    <Typography style={customerManagementStyles.sectionContent} align="left">
                        {(language as any)[currentLanguage].customerMgmtSearchSubText}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                    <Typography style={customerManagementStyles.sectionInputHeader} align="left">
                        {
                            (language as any)[currentLanguage].arNumber
                        } ({(language as any)[currentLanguage].required}):</Typography>
                    <TextField
                        data-testid="ar_number_input"
                        label="ex: 1234567"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={arSearchValue}
                        onChange={(event)=>setARSearchValue(event.target.value)}
                    />
                </Box>
                
                <Button variant="contained" color="primary" fullWidth sx={{height: '50px'}} 
                    data-testid="ar_number_search"
                    disabled={arSearchValue ? false : true}
                    onClick={() => searchCustomerDetails()}
                >
                    {(language as any)[currentLanguage].search}
                </Button>
            </Box>
            </Grid>
            <Grid item xs={9}>
                <CustomerDetailsList
                    customerDetails={errorCustomersQuery ? emptyCustomerResponse() : customerDetails as CustomerResponse}
                    handlePageChange={(event)=>{setPage(event+1)}}
                    handleRowsPerPageChange={(event)=>{setPageSize(event)}}
                    onCustomerDetailsClick={(event: CustomerDetails) => {
                        changeSelectedCustomer(event)
                    }}
                ></CustomerDetailsList>
            </Grid>
        </Grid>
        </>
    )
}

export default CustomerManagement;