export const CustomerAddressStyles = {
    container: {
        display: 'flex', 
        flexDirection: 'column',
        gap: '8px', 
        alignItems: 'start'
    },
    containerRow: {
        width: '100%'
    },
    addressHeader: {
        display: 'flex', 
        alignItems: 'center', 
        gap: '6px'
    },
    checkbox: {
        padding: '0px'
    },
    addressContainer: { 
        padding: '0px 16px'
    },
    addressRow: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column', 
        paddingBottom: '8px'
    },
    specialAddressRow: {
        display: "flex",
        flexDirection: "row", 
        justifyContent: "space-between", 
        gap: "16px",
        textAlign: 'left',
        paddingBottom: '8px'
    },
    cityContainer: { flexGrow: '1'},
    stateContainer: { width: '70px' },
    stateSelector: { width: '100%', height: '33px'},
    zipContainer: { width: '100px' },
    autofillContainer: { width: '100%'},
    phoneInput: {
        border: '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        width: '150px',
        padding: '4px 8px',
        textAlign: 'center'
    },
    dropOffContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        gap: '4px'
    },
    dropOffEditor: {
        display: 'flex',
        flexDirection: 'column', 
        padding: '0px 16px',
        gap: '4px'
    },
    coordinatesinfo: {
        display: 'flex', 
        flexDirection: 'row', 
        gap: '4px'
    },
    notesContainer: {
        width: "100%"
    },
    notesInput: { padding: '0px 20px' },
    buttonContainer: { width: '100%', paddingTop: '8px', display: "flex", justifyContent: "space-between"}
}