import { Button, Divider, ListItemText, Menu, MenuItem } from '@mui/material'
import { useContext, useState } from 'react'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import { navigationBarStyles as styles } from '../../components/NavigationBar/navigationBarStyles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SupportedLanguage } from '../../language/LanguageTypes'

type menuItem = {
  menuName: string | null | undefined
  icon: JSX.Element
  onClick?: (arg0: any) => void
}

const LanguageChange = () => {
  const { currentLanguage, changeLanguage } = useContext(LanguageContext)
  const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(null)
  const { isFrenchEnabled } = useFlags()
  const handleLangChange = (value: SupportedLanguage) => {
    setAnchorElLanguage(null)
    changeLanguage(value)
  }

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  const menus = [
    {
      menuName: '',
      icon: (
        <>
          <LanguageIcon sx={styles.navBarMenuIcon} />
          <KeyboardArrowDownSharpIcon sx={styles.navBarMenuIcon} />
        </>
      ),
      onClick: handleLanguageChange
    }
  ]

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isFrenchEnabled &&
          menus.map((menu: menuItem) => (
            <Button
              sx={{ ...styles.navbarButtonStyle, fontWeight: 'bold', textTransform: 'none' }}
              startIcon={menu.icon}
              onClick={menu.onClick} data-testid='languageDropdown'>
              {menu.menuName}
            </Button>
          ))}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        defaultValue={currentLanguage}
        onClose={handleCloseLanguage}
        sx={styles.menuList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleLangChange('en')}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => handleLangChange('fr')}>
          <ListItemText>French</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default LanguageChange
