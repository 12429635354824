import Colors from '../../assets/Colors'

export const LandingStyles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh'
  },
  content: {
    width: '330px'
  },
  logo: {
    height: 110,
    width: 105
  },
  brand: {
    color: Colors.napaBlue,
    fontFamily: 'NAPA SANS',
    fontStyle: 'normal',
    fontWeight: '700',
    margin: '5px 0 40px 0'
  }
}
